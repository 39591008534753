import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Duration } from 'luxon';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { WithIndex } from '../../helpers/types';
import { Step } from '../../models/step';

type Props = {
    step: Step,
    stageIndex: number,
    stepIndex: number,
    inProgressStep: WithIndex<Step> | null | true
};

const defaultColor = 'inherit';

const StepRow = ({ stageIndex, stepIndex, step, inProgressStep }: Props) => {
    const navigate = useNavigate();
    const goToStep = useCallback(() => {
        navigate(`${stageIndex}.${stepIndex}/details`);
    }, [navigate, stageIndex, stepIndex]);

    const backgroundColor = useMemo(() => inProgressStep === null || (inProgressStep !== true && inProgressStep.index > stepIndex) ? defaultColor : (inProgressStep === true || inProgressStep.index > stepIndex) ? 'steps.complete.main' : 'steps.inProgress.main', [inProgressStep, stepIndex]);
    
    return (
        <ListItem>
            <ListItemButton sx={{ backgroundColor }} onClick={goToStep}>
                <ListItemText inset primary={`(${Duration.fromMillis(step.duration).shiftTo('minutes').get('minutes')} minutes): ${step.instructions}`} />
            </ListItemButton>
        </ListItem>
    );
};

export default StepRow;