import { onAuthStateChanged } from 'firebase/auth';
import { createContext, ReactNode, useEffect, useState } from 'react';
import { auth } from '../firebase/auth';
import { fromFirebase, User } from '../models/user';

type UnauthenticatedData = {
    loggedIn: false
};

type AuthenticatedData = {
    loggedIn: true,
    user: User
};

type Data = UnauthenticatedData | AuthenticatedData;

type Props = {
    children?: ReactNode
};

export const UserContext = createContext<Data>({
    loggedIn: false
});
  
export const UserContextProvider = ({ children }: Props) => {
    const [user, setUser] = useState<User | null>(null);
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/firebase.User
              setUser(fromFirebase(user));
            } else {
              // User is signed out
              // ...
              setUser(null);
            }
        });
    }, []);

    const data = user ? {
        user,
        loggedIn: true as const
    } : {
        loggedIn: false as const
    };

    return (
        <UserContext.Provider value={data}>
        { children || null }
        </UserContext.Provider>
    );
};