import { repoFactory } from './base';
import { Tag, Type } from '../models/tag';

import { writeBatch, where, doc } from 'firebase/firestore';
import { db } from '../firebase/firestore';

const base = repoFactory<Tag>({ collectionName: 'tags' });

const repo = {
  ...base,
  async getOneTag(type: Type, value: string) {
    const typeSearch = type.toUpperCase();
    const valueSearch = value.toUpperCase();
    const results = await this.filter(where('type', '==', typeSearch), where('search', '==', valueSearch));
    return results[0] || null;
  },
  async findTags(type: Type, prefix: string) {
    const typeSearch = type.toUpperCase();
    const valueSearch = prefix.toUpperCase();

    return this.filter(
      where('type', '==', typeSearch), where('search', '>=', valueSearch), where('search', '<=', valueSearch + '\uf8ff')
    );
  },
  async ensureTags(tags: Tag[]) {
    const batch = writeBatch(db);
    const collection = this.col();
    if (this.isCollection(collection)) {
      tags.map(tag => ({ tag, tagDoc: doc(collection, tag.id) })).forEach(({tag, tagDoc}) => {
        batch.set(tagDoc, tag);
      });
    }

    await batch.commit();
  }
};

export default repo;
