// A step in the process with specific details on timing,
// implementation, and ingredients.

import { Timestamp } from 'firebase/firestore';
import { convertToNumber, maxBy } from '../helpers/functions';
import { Note } from './note';
import { StepTemplate } from './template';

export type Step = StepTemplate & {
  startTime: Timestamp,
  endTime: Timestamp | null,
  notes: Note[]
};

export const fromTemplate = (template: StepTemplate, startTime: Timestamp) => ({
  ...template,
  startTime,
  endTime: null,
  notes: []
});

export const getLatestNote = (step: Step): Note | null => {
  return step.notes.length ? maxBy(step.notes, n => n.timestamp) : null;
};

export type Index = {
  stepIdentifier: number,
  stageIdentifier: number
};

export type Identifier = `${number}.${number}`;

export const fromIndexToIdentifier = (idx: Index): Identifier => `${idx.stageIdentifier}.${idx.stepIdentifier}`;
export const fromIdentifierToIndex = (id: Identifier): Index | null => {
  const [, stageIndex, stepIndex] = /^([\d]+)\.([\d]+)$/.exec(id || '') || [];
  const stageIdentifier = convertToNumber(stageIndex);
  const stepIdentifier = convertToNumber(stepIndex);

  if (stageIdentifier === null || stepIdentifier === null) {
    return null;
  }

  return {
    stageIdentifier,
    stepIdentifier
  };
};
