
import { Button, Card, CardActions, CardContent, CardHeader, Dialog, FormControl, InputLabel, LinearProgress, Link, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { logger } from 'workbox-core/_private';
import brewRepo from '../../repositories/brews';

type EmptyProps = {
    onContinue: (brewId: string, expectedStartTime: DateTime) => void,
    onCancel: () => void,
    open: boolean
};

type FullProps = EmptyProps & {
    brewId: string,
    brewName: string
};

type Props = EmptyProps | FullProps;

type SimpleBrew = {
    id: string,
    name: string
};

const isPopulated = (props: Props): props is FullProps => {
    return 'brewId' in props;
};

const NewBrewdayModal = (props: Props) => {
    const { open, onContinue, onCancel } = props;
    const [allBrews, setAllBrews] = useState<SimpleBrew[]>([]);
    const [loadingBrews, setLoadingBrews] = useState(false);
    const brewId = isPopulated(props) ? props.brewId : '';
    useEffect(() => {
        if (open && !brewId) {
            setLoadingBrews(true);
            brewRepo.getAllActive().then(brews => brews.map(b => ({ id: b.id, name: b.name }))).then(setAllBrews).finally(() => setLoadingBrews(false));
        } else {
            setLoadingBrews(false);
            setAllBrews([]);
        }
    }, [open, brewId, setAllBrews]);

    const defaultBrew = useMemo(() => isPopulated(props) ? { id: props.brewId, name: props.brewName } : { id: '', name: '' }, [props]);

    const [expectedStartTime, setExpectedStartTime] = useState<DateTime>(DateTime.now());
    const handleStartTimeChange = useCallback((datetime: DateTime | null) => {
        logger.debug('New start time received:', datetime);
        setExpectedStartTime(datetime || DateTime.now());

    }, [setExpectedStartTime]);

    const [selectedBrew, setSelectedBrew] = useState<SimpleBrew>(defaultBrew);

    const isValid = useMemo(() => selectedBrew && selectedBrew.id && expectedStartTime.isValid, [selectedBrew, expectedStartTime.isValid]);
    const save = useCallback(() => {
        if (isValid) {
            console.log('New brewday starting:', selectedBrew.id, expectedStartTime);
            onContinue(selectedBrew.id, expectedStartTime);
        }
    }, [selectedBrew, onContinue, expectedStartTime, isValid]);

    const brewOptions = useMemo(() => {
        return allBrews.map(brew => (
            <MenuItem key={brew.id} value={brew.id}>{brew.name}</MenuItem>
        ));
    }, [allBrews]);

    const helpText = useMemo(() => {
        const brewName = selectedBrew.name;
        if (brewName) {
            return `This will convert the brew template for ${brewName} into the pattern for an upcoming brewday, which may be started at any time.`;
        } else {
            return 'This will convert a brew template into the pattern for an upcoming brewday, which may be started at any time.';
        }
    }, [selectedBrew]);

    const selectComponent = useMemo(() => {
        if (brewId) {
            return null;
        }

        if (loadingBrews) {
            return <LinearProgress />;
        }

        if (!brewOptions.length) {
            return <Typography variant="body2" sx={{ marginTop: theme => theme.spacing(1) }}>Looks like you haven't made any brew templates yet, you should go to <Link to="/brews" component={RouterLink}>Brews</Link> to get started!</Typography>;
        }

        return (
            <FormControl fullWidth>
                <InputLabel id="brew-select">Brew Template</InputLabel>
                <Select labelId="brew-select" label="Brew Template" onChange={e => setSelectedBrew({ id: e.target.value as string, name: e.target.name })}>{brewOptions}</Select>
            </FormControl>
        );
    }, [brewId, loadingBrews, brewOptions, setSelectedBrew]);

    const closeModal = useCallback(() => {
        handleStartTimeChange(null);
        onCancel();
    }, [handleStartTimeChange, onCancel]);

    return (
        <Dialog open={open} onClose={closeModal}>
            <Card sx={{ minWidth: 350, maxWidth: 500 }}>
                <CardHeader title="Set up a new Brewday" />
                <CardContent>
                    <Stack>
                        <Typography>{helpText}</Typography>
                    </Stack>
                    {
                        selectComponent 
                    }
                    <DateTimePicker
                    value={expectedStartTime}
                    onChange={handleStartTimeChange}
                    renderInput={(props) => <TextField {...props} />}
                    label="Expected Start Time"
                    disablePast
                    disableMaskedInput
                    />
                </CardContent>
                <CardActions>
                    <Button onClick={closeModal}>Cancel</Button>
                    <Button color="primary" disabled={!isValid} onClick={save}>Continue</Button>
                </CardActions>
            </Card>
        </Dialog>
    );
};

export default NewBrewdayModal;