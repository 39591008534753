import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { ChangeEventHandler, ComponentProps, useCallback, useMemo, useState } from 'react';
import { emptyStage, StageTemplate } from '../../models/template';

type Props = ComponentProps<typeof Dialog> & {
    template?: StageTemplate,
    onSave: (template: StageTemplate) => void
    onCancel: () => void
};

const StageTemplateDialog = ({ template: initialTemplate, onSave, onCancel, ...dialogProps }: Props) => {
    const template = useMemo(() => initialTemplate || emptyStage(), [initialTemplate]);
    const [name, setName] = useState(template.name);
    const reset = useCallback(() => setName(template.name), [setName, template.name]);

    const save = useCallback(() => {
        onSave({
            ...template,
            name
        });

        reset();
    }, [onSave, template, name, reset]);

    const cancel = useCallback(() => {
        onCancel();
        reset();
    }, [onCancel, reset]);

    const onChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback(e => setName(e.target.value), [setName]);

    return (
        <Dialog onClose={onCancel} {...dialogProps}>
            <DialogTitle>Stage Template</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Stage Name"
                    fullWidth
                    variant="standard"
                    onChange={onChange}
                    value={name}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={cancel}>Cancel</Button>
                <Button color="primary" variant="contained" onClick={save}>{initialTemplate ? 'Update' : 'Create'}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default StageTemplateDialog;