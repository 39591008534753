import { ActionCodeSettings, EmailAuthProvider, fetchSignInMethodsForEmail, getAuth, GoogleAuthProvider, sendSignInLinkToEmail, signInWithEmailAndPassword, signInWithEmailLink, User, signOut as firebaseSignOut } from 'firebase/auth';

import { app } from './app';
import config from '../config';
import logger from '../helpers/logger';
import { LoginOptions } from '../models/user';
import { getLocal } from '../services/storage';

export const auth = getAuth(app);

const EMAIL_KEY = 'login-email';
const getEmailStore = () => getLocal<string>();

export const redirectRoutePrefix = '/auth/redirect';
const actionCode: ActionCodeSettings = {
  url: `${config.app.baseUrl}${redirectRoutePrefix}/signin`,
  handleCodeInApp: true
};

if (config.localAuth) {
  signInWithEmailAndPassword(auth, config.localAuth.email, config.localAuth.password)
    .catch(err => logger.error('Error while trying to authenticate: ', err));
}

export const getPreviousLoginOptionsForEmail = (email: string): Promise<LoginOptions | null> => {
  return fetchSignInMethodsForEmail(auth, email).then(methods => ({
    password: methods.includes(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD),
    emailLink: methods.includes(EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD),
    google: methods.includes(GoogleAuthProvider.PROVIDER_ID),
    none: methods.length === 0
  })).catch(err => {
    logger.error('Error occured while determining login options', err);
    return null;
  });
};

export enum LoginError {
  EMAIL_REQUIRED,
  UNKNOWN_ERROR
};

export const handleRedirect = (email?: string): Promise<User | LoginError> => {
    const storedEmail = email || getEmailStore().load(EMAIL_KEY);
    if (!storedEmail) {
      return Promise.resolve(LoginError.EMAIL_REQUIRED);
    }

    return signInWithEmailLink(auth, storedEmail, window.location.href)
      .then(result => {
        getEmailStore().delete(EMAIL_KEY);
        return result.user;
      })
      .catch(e => {
        logger.error('Error occured while handling a redirect.', e);
        return LoginError.UNKNOWN_ERROR;
      });
};

export const loginUsingEmailLink = (email: string) => {
  logger.debug('Logging in with link for email', email);
  getEmailStore().save(EMAIL_KEY, email);
  return sendSignInLinkToEmail(auth, email, actionCode);
};

export const signOut = () => {
  return firebaseSignOut(auth);
};
