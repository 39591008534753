import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { ChangeEventHandler, ComponentProps, useCallback, useMemo, useState } from 'react';
import { emptyStep, StepTemplate } from '../../models/template';

type Props = ComponentProps<typeof Dialog> & {
    template?: StepTemplate,
    onSave: (template: StepTemplate) => void
    onCancel: () => void
};

const StepTemplateDialog = ({ template: initialTemplate, onSave, onCancel, ...dialogProps }: Props) => {
    const template = useMemo(() => initialTemplate || emptyStep(), [initialTemplate]);
    const [instructions, setInstructions] = useState(template.instructions);
    const reset = useCallback(() => setInstructions(template.instructions), [setInstructions, template.instructions]);

    const save = useCallback(() => {
        onSave({
            ...template,
            instructions
        });

        reset();
    }, [onSave, template, instructions, reset]);

    const cancel = useCallback(() => {
        onCancel();
        reset();
    }, [onCancel, reset]);

    const onChange: ChangeEventHandler<HTMLTextAreaElement> = useCallback(e => setInstructions(e.target.value), [setInstructions]);

    return (
        <Dialog onClose={onCancel} {...dialogProps}>
            <DialogTitle>Step Template</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="instructions"
                    label="Instructions"
                    fullWidth
                    variant="standard"
                    multiline
                    onChange={onChange}
                    value={instructions}
                />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={cancel}>Cancel</Button>
                <Button color="primary" variant="contained" onClick={save}>{initialTemplate ? 'Update' : 'Add'}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default StepTemplateDialog;