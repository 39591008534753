import { Button, Stack, TextField } from '@mui/material';
import { ChangeEvent, ComponentProps, useCallback, useEffect, useMemo, useState } from 'react';
import { LoginOptions } from '../../models/user';

type Props = {
    loginOptions: LoginOptions,
    onPasswordChanged: (e: ChangeEvent<HTMLInputElement>) => void,
    password: string,
    onLinkClicked: () => void,
    onLoginAllowed: (allowed: boolean) => void
};

type EmailLinkProps = { onClick: () => void };
const EmailLink = ({ onClick }: EmailLinkProps) => (
    <Button variant="contained" onClick={onClick}>E-mail my sign-in link</Button>
);

type PasswordProps = ComponentProps<typeof TextField> & {
    value: string,
    onChange: (e: ChangeEvent<HTMLInputElement>) => void,
    label?: string
};
const Password = ({ value, onChange, label = 'Password', ...rest }: PasswordProps) => (
    <TextField
        margin="dense"
        id={`login-${label.toLowerCase()}`}
        label={label}
        fullWidth
        type="password"
        variant="standard"
        required
        onChange={onChange}
        value={value}
        {...rest}
    />
);

const switchToPasswordText = 'Use a password instead';
const switchToLinkText = 'Send me a link instead (recommended)';
const confirmNotMatchMessage = 'Passwords must match';

const LoginOptionsForm = ({ loginOptions, onPasswordChanged, password, onLinkClicked, onLoginAllowed }: Props) => {
    const [showLink, setShowLink] = useState(loginOptions.emailLink || loginOptions.none);
    const canToggle = useMemo(() => (loginOptions.emailLink && loginOptions.password) || loginOptions.none, [loginOptions]);
    const toggle = useCallback(() => setShowLink(x => !x), [setShowLink]);

    const [confirmedPassword, setConfirmedPassword] = useState('');
    const handleConfirmPasswordChange = useCallback((e: ChangeEvent<HTMLInputElement>) => setConfirmedPassword(e.target.value), [setConfirmedPassword]);
    const passwordsMatch = useMemo(() => password === confirmedPassword, [password, confirmedPassword]);
    const confirmHasError = useMemo(() => !!confirmedPassword.length && !passwordsMatch, [confirmedPassword.length, passwordsMatch]);

    useEffect(() => {
        const valid = !showLink && password.length && (!loginOptions.none || passwordsMatch);
        onLoginAllowed(!!valid);
    }, [showLink, password, loginOptions, passwordsMatch, onLoginAllowed]);

    const mainElement = showLink ? <EmailLink onClick={onLinkClicked} /> : <Password value={password} onChange={onPasswordChanged} />;
    const confirmInput = loginOptions.none && !showLink ? <Password value={confirmedPassword} onChange={handleConfirmPasswordChange} label="Confirm Password" error={confirmHasError} helperText={confirmHasError ? confirmNotMatchMessage : ''} /> : null;
    const toggleElement = canToggle ? <Button onClick={toggle}>{showLink ? switchToPasswordText : switchToLinkText}</Button> : null;

    return (
        <Stack sx={{ justifyContent: 'space-around', marginTop: theme => theme.spacing(2) }}>
        {mainElement}
        {confirmInput}
        {toggleElement}
        </Stack>
    );
};

export default LoginOptionsForm;