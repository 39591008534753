import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { getPreviousLoginOptionsForEmail, loginUsingEmailLink } from '../../firebase/auth';
import logger from '../../helpers/logger';
import { LoginOptions } from '../../models/user';
import LoginOptionsForm from './LoginOptionsForm';
import { CloseIcon } from '../icons';

const stepButtonTexts = ['Next', 'Login', 'Close'];

type Props = {
    open: boolean,
    onClose: () => void
};

const LoginDialog = ({ open, onClose }: Props) => {
    const [currentStep, setCurrentStep] = useState(0);
    const nextStep = useCallback(() => setCurrentStep(s => s + 1), [setCurrentStep]);
    const backStep = useCallback(() => setCurrentStep(s => s - 1), [setCurrentStep]);
    const emailStep = currentStep === 0;
    const signinStep = currentStep === 1;
    const doneStep = currentStep === 2;

    const [email, setEmail] = useState('');
    const onChangeEmail = useCallback((e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value), [setEmail]);

    const [password, setPassword] = useState('');
    const onChangePassword = useCallback((e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value), [setPassword]);

    const [loginOptions, setLoginOptions] = useState<LoginOptions | null>(null);
    const [passwordLoginEnabled, setPasswordLoginEnabled] = useState(false);
    
    const closeModal = useCallback(() => {
        setCurrentStep(0);
        setEmail('');
        onClose();
    }, [setCurrentStep, setEmail, onClose]);
    const onModalClose = useCallback((_, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason === 'escapeKeyDown') {
            closeModal();
        }
    }, [closeModal]);

    const backCallback = useMemo(() => {
        if (emailStep) {
            return closeModal;
        }

        return backStep;
    }, [emailStep, backStep, closeModal]);

    const nextCallback = useMemo(() => {
        if (emailStep) {
            return () => getPreviousLoginOptionsForEmail(email).then(setLoginOptions).then(nextStep);
        }

        if (doneStep) {
            return closeModal;
        }

        return () => logger.info('Trying to login with email/password');
    }, [emailStep, email, setLoginOptions, nextStep, doneStep, closeModal]);

    const linkClicked = useCallback(() => loginUsingEmailLink(email).then(nextStep), [email, nextStep]);

    return (
        <Dialog keepMounted BackdropProps={{ sx: { backgroundColor: 'rgba(0, 0, 0, 0.1)' } }} open={open} onClose={onModalClose}>
            <DialogTitle>
                <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography>Login</Typography>
                    <IconButton size="small" onClick={closeModal}><CloseIcon /></IconButton>
                </Stack>
            </DialogTitle>

            <DialogContent>
                <Stepper activeStep={currentStep}>
                    <Step>
                        <StepLabel>E-mail</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Verify</StepLabel>
                    </Step>
                    <Step>
                        <StepLabel>Complete</StepLabel>
                    </Step>
                </Stepper>
                {
                    emailStep && (
                        <TextField
                            autoFocus
                            margin="dense"
                            id="login-email"
                            label="E-Mail"
                            fullWidth
                            variant="standard"
                            onChange={onChangeEmail}
                            value={email}
                        />
                    )
                }
                {
                    signinStep && loginOptions && <LoginOptionsForm loginOptions={loginOptions} onLinkClicked={linkClicked} password={password} onPasswordChanged={onChangePassword} onLoginAllowed={setPasswordLoginEnabled} />
                }
                {
                    doneStep && (
                    <>
                    <Typography>Please check your e-mail for a link to log in to Brewday Helper.</Typography>
                    <Typography>It is recommended to use this device for an easier experience.</Typography>
                    </>
                    )
                }
            </DialogContent>
            <DialogActions>
                {
                    !doneStep && <Button onClick={backCallback}>{currentStep === 0 ? 'Cancel' : 'Back' }</Button>
                }
                <Button disabled={signinStep && !passwordLoginEnabled} onClick={nextCallback}>{stepButtonTexts[currentStep]}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default LoginDialog;