import { useContext } from 'react';
import { UserContext } from '../contexts/UserContext';
import { User } from '../models/user';

export const useCurrentUser = (): User | null => {
    const user = useContext(UserContext);

    if (user.loggedIn) {
        return user.user;
    }

    return null;
};
