import { WindowOpener } from '../../helpers/contextual-functions';
import logger from '../../helpers/logger';
import { getStageAndStepByIndex } from '../../models/brewday';
import { fromIndexToIdentifier, Step } from '../../models/step';
import { forBrew } from '../../repositories/brewdays';
import { NotificationProcessor } from '../notifications/types';
import { BrewdayStepNotificationData, expectedType } from './event';

const processTypes = ['complete', 'skip', 'open'] as const;
export type ProcessType = typeof processTypes[number];

const isProcessType = (action?: string | ProcessType): action is ProcessType => {
    return processTypes.includes(action as ProcessType);
};

export const processor: NotificationProcessor<typeof expectedType, BrewdayStepNotificationData, void | WindowClient | null> = {
    type: expectedType,
    process: (notificationData: BrewdayStepNotificationData, openWindow: WindowOpener, action?: string) => {
        action = action || 'open';
        if (!isProcessType(action)) {
            return Promise.reject();
        }
        const stage = notificationData.stageIndex;
        const step = notificationData.stepIndex;
        if (action === 'complete' || action === 'skip') {
            const repo = forBrew(notificationData.brewId);
            let notes: string | undefined;
            if (action === 'skip') {
                notes = 'Skipped this step!';
            }

            return repo.completeStep(notificationData.brewdayId, stage, step, notes).then(() => logger.debug('Marked step as completed through a notification'));
        } else {
            const identifier = fromIndexToIdentifier({ stageIdentifier: stage, stepIdentifier: step });
            return openWindow(`brewdays/${notificationData.brewId}/${notificationData.brewdayId}/stages/${identifier}/${action}`);
        }
    }
};

export const getDisplayText = (notificationData: BrewdayStepNotificationData, step: Step): string => {
    const duration = notificationData.expectedCompletionTime.diffNow().shiftTo('hours', 'minutes', 'seconds').toHuman({ listStyle: 'long' });
    return `In ${duration}, ${step.instructions}`;
};

export const retrieveRelatedData = (notificationData: BrewdayStepNotificationData) => {
    logger.info('Retrieving related data for notification:', notificationData);
    const repo = forBrew(notificationData.brewId);
    return repo.getOne(notificationData.brewdayId).then(brewday => {
        if (!brewday) {
          logger.error('Received a notification for a brewday that does not exist:', notificationData);
          throw Error('Could not process notification');
        }

        const matchingStep = getStageAndStepByIndex(brewday, notificationData.stageIndex, notificationData.stepIndex);
        if (!matchingStep) {
          logger.error('Received a notification for a step that does not exist on the brewday:', notificationData);
          throw Error('Could not process notification');
        }

        return {
          brewday,
          ...matchingStep
        };
    });
};
