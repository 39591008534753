import { useEffect, useMemo, useState } from 'react';
import { Brew } from '../models/brew';
import { Brewday, PopulatedBrewday } from '../models/brewday';
import brewRepo from '../repositories/brews';
import usePopulatedBrew from './usePopulatedBrew';

const usePopulatedBrewday = (basicBrewday?: Brewday | null): PopulatedBrewday | null => {
    const [brew, setBrew] = useState<Brew | null>(null);
    useEffect(() => {
        const brewId = basicBrewday?.brewLink.id;
        if (brewId) {
            brewRepo.getOne(brewId).then(b => setBrew(b));
        }
    }, [basicBrewday?.brewLink.id]);

    const populatedBrew = usePopulatedBrew(brew);

    const populatedBrewday = useMemo(() => {
        if (!basicBrewday || !populatedBrew) {
            return null;
        }

        const pb: PopulatedBrewday = {
            ...basicBrewday,
            brew: populatedBrew,
            id: basicBrewday.id!
        };

        return pb;
    }, [populatedBrew, basicBrewday]);

    return populatedBrewday;
};

export default usePopulatedBrewday;
