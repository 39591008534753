import { deleteDoc, limit, Timestamp, where } from 'firebase/firestore';
import { DeviceRegistration } from '../models/device-registration';
import { repoFactory } from './base';

const base = repoFactory<DeviceRegistration>({ collectionName: 'device-registrations' });

const repo = {
    ...base,
    async registerDevice(userId: string, deviceId: string) {
        return this.save({
            id: deviceId,
            userId,
            timestamp: Timestamp.now()
        });
    },
    async disableDevice(deviceId: string) {
        return deleteDoc(this.getRequiredDoc(deviceId));
    },
    async getRegisteredDevices(userId: string) {
        return this.filter(where('userId', '==', userId));
    },
    async userHasOtherDevice(userId: string) {
        return this.filter(where('userId', '==', userId), limit(1)).then(r => r.length > 0);
    }
};

export default repo;
