import Typography from '@mui/material/Typography';
import { StageAndStep } from '../../models/brewday';
import { Stage } from '../../models/stage';
import StageRow from './StageRow';

type Props = {
    stages: Stage[],
    inProgressStageAndStep: StageAndStep | null
};

const NoStagesComponent = () => (
    <>
        <Typography variant="subtitle2">Looks like you haven't set up the steps for brewing yet!</Typography>
    </>
);

const Stages = ({
    stages,
    inProgressStageAndStep
}: Props) => {
    return (
        <>
            {
                stages.map((stage, stageIndex) => (
                    <StageRow key={stage.name} stage={stage} stageIndex={stageIndex} inProgressStageAndStep={inProgressStageAndStep} />
                ))
            }
            {
                stages.length ? null : <NoStagesComponent />
            }
        </>
    );
};

export default Stages;