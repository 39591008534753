import { useState, useEffect, useCallback, useMemo, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import BrewTable from '../../components/brews/BrewTable';
import useActions from '../../hooks/useActions';
import { Brew, textMatches } from '../../models/brew';
import { AddIcon, SelectedListItemIcon } from '../../components/icons';
import repo, { BrewFilter } from '../../repositories/brews';
import TableFilterToolbar from '../../components/table/TableFilterToolbar';
import ToggleFilterListItem from '../../components/table/ToggleFilterListItem';
import useRepoFilter from '../../hooks/useRepoFilter';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { Style } from '../../models/style';
import StylePicker from '../../components/form/StylePicker';
import { ListItemText, MenuItem } from '@mui/material';

const defaultFilter: BrewFilter = {
    active: true,
    published: true
};

const Brews = () => {
    const navigate = useNavigate();
    const currentUser = useCurrentUser();
    const [brews, setBrews] = useState([] as Brew[]);

    useActions([
        {
            icon: AddIcon,
            label: 'Start Brewday',
            onSelected: () => navigate('create')
        }
    ], [navigate]);

    const brewSelected = useCallback((brew: Brew) => navigate(brew.id!), [navigate]);
    const [filterStyle, setFilterStyle] = useState<Style | null>(null);

    const {
        builder,
        setBuilder,
        handleToggle
    } = useRepoFilter(defaultFilter);

    useEffect(() => {
        if (!filterStyle && builder.filter.style) {
            setBuilder(b => b.without('style'));
        } else if (filterStyle && filterStyle.id !== builder.filter.style) {
            setBuilder(b => b.with('style', filterStyle.id));
        }
    }, [builder, filterStyle, setBuilder]);
    const switchOwner = useCallback(() => setBuilder(b => {
        if (b.filter.ownerId) {
            return b.without('ownerId').with('published', true).with('active', true);
        } else if (currentUser) {
            return b.without('published').with('ownerId', currentUser.userId);
        } else {
            return b;
        }
    }), [currentUser, setBuilder]);
    
    const filterComponents = useMemo(() => [
        <ToggleFilterListItem key="1" onClick={switchOwner} label="Only mine" selected={!!builder.filter.ownerId} />,
        <ToggleFilterListItem key="2" onClick={handleToggle('active')} label="Active" selected={!!builder.filter.active} disabled={!builder.filter.ownerId}/>,
        <MenuItem key="3" sx={{ minWidth: 300 }}>
            <SelectedListItemIcon selected={!!filterStyle} />
            <ListItemText inset={!filterStyle} disableTypography>
                <StylePicker value={filterStyle} onChange={setFilterStyle}/>
            </ListItemText>
        </MenuItem>
    ], [builder, switchOwner, handleToggle, filterStyle]);

    useEffect(() => {
        repo.filter(...builder.build()).then(brewData => {
            setBrews(brewData);
        });
    }, [setBrews, builder]);

    const [searchText, setSearchText] = useState('');
    const onSearchTextChanged = useCallback((e: ChangeEvent<HTMLInputElement>) => setSearchText(e.currentTarget.value), [setSearchText]);

    const textMatchedBrews = useMemo(() => !searchText ? brews :  brews.filter(brew => textMatches(brew, searchText)), [brews, searchText]);

    return (
        <>
        <TableFilterToolbar tableName="Brews" menuItems={filterComponents} searchText={searchText} onSearchTextChanged={onSearchTextChanged} />
        <BrewTable brews={textMatchedBrews} onBrewSelected={brewSelected} />
        </>
    );
};

export default Brews;
