import LinearProgress from '@mui/material/LinearProgress';
import { useEffect, useState, useMemo } from 'react';
import { Navigate, Route, Routes, useMatch, useParams } from 'react-router-dom';
import { Brew } from '../../models/brew';
import brewRepo from '../../repositories/brews';
import BrewDetailsEdit from './BrewDetailsEdit';
import BrewDetails from './BrewDetails';
import Box from '@mui/material/Box';
import BrewStages from './BrewStages';
import BrewStagesEdit from './BrewStagesEdit';
import BrewBottles from './BrewBottles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Link from '@mui/material/Link';
import usePopulatedBrew from '../../hooks/usePopulatedBrew';
import { RoutedBrewBottleDetails } from './BrewBottleDetails';
import AuthenticatedRoutes from '../../components/AuthenticatedRoute';
import { useCurrentUser } from '../../hooks/useCurrentUser';

type Params = {
    brewId: string
};

const BrewRoutes = () => {
    const currentUser = useCurrentUser();
    const params = useParams<Params>();
    const [brew, setBrew] = useState(null as Brew | null);
    useEffect(() => {
        if (params.brewId) {
            return brewRepo.listenToDocument(setBrew, params.brewId);
        }
    }, [params.brewId, setBrew]);

    const populatedBrew = usePopulatedBrew(brew);

    const match = useMatch('brews/:brewId/:subroute/*');
    const routeMatch = useMemo(() => match?.params.subroute, [match]);

    if (!populatedBrew) {
        return <LinearProgress />;
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Tabs value={routeMatch || false} sx={{ marginBottom: (theme) => theme.spacing(1) }}>
                <Tab
                component={Link}
                href="details"
                value="details"
                label="Details"
                />
                {
                currentUser?.userId === populatedBrew.ownerId ?
                ( <Tab
                component={Link}
                href="stages"
                value="stages"
                label="Stages"
                /> ) : null
                }
                <Tab
                component={Link}
                href="bottles"
                value="bottles"
                label="Bottles"
                />
            </Tabs>
            <Routes>
                <Route index element={<Navigate to="details" replace />} />
                <Route path="details" element={<BrewDetails brew={populatedBrew}/>}/>
                <Route path="stages" element={<BrewStages brew={populatedBrew}/>}/>
                <Route path="bottles" element={<BrewBottles brewId={populatedBrew.id}/>}/>
                <Route element={<AuthenticatedRoutes />}>
                    <Route path="details/edit" element={<BrewDetailsEdit initialBrew={populatedBrew}/>}/>
                    <Route path="stages/edit" element={<BrewStagesEdit brew={populatedBrew}/>}/>
                    <Route path="bottles/:brewdayId/:bottleId" element={<RoutedBrewBottleDetails />}/>
                </Route>
            </Routes>
        </Box>
    );
};

export default BrewRoutes;