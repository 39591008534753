import { ListItemText, ListItemIcon, MenuItem } from '@mui/material';
import { ComponentProps, useMemo } from 'react';
import { CompleteCheckmark as CheckIcon } from '../icons';

type Props = ComponentProps<typeof MenuItem> & {
    selected: boolean,
    label: string,
    onClick: () => void
};

const ToggleFilterListItem = ({ selected, label, onClick, ...rest }: Props) => {
    const iconElement = useMemo(() => selected ? <ListItemIcon><CheckIcon /></ListItemIcon> : null, [selected]);

    return (
        <MenuItem onClick={onClick} {...rest}>
            {iconElement}
            <ListItemText inset={!selected}>
                {label}
            </ListItemText>
        </MenuItem>
    );
};

export default ToggleFilterListItem;
