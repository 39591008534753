import StageTemplates from '../../components/stages/StageTemplates';
import { PopulatedBrew } from '../../models/brew';
import repo from '../../repositories/brews';
import { useCallback } from 'react';
import { StageTemplate } from '../../models/template';
import { useNavigate } from 'react-router-dom';

type Props = {
    brew: PopulatedBrew
};

const BrewStagesEdit = ({ brew }: Props) => {
    const navigate = useNavigate();
    const goBack = useCallback(() => navigate('..'), [navigate]);
    const submit = useCallback((templates: StageTemplate[]) => {
        repo.updateStages(brew.id, templates).then(goBack);
    }, [goBack, brew.id]);

    
    return <StageTemplates initialStages={brew.stages} brewName={brew.name} onSave={submit} onCancel={goBack} />;
};

export default BrewStagesEdit;