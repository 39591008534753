import { darken, styled } from '@mui/material';
import { useCallback } from 'react';
import { Sort } from '../../repositories/base';
import { ExpandLessIcon, ExpandMoreIcon } from '../icons';
import TableHeaderCell from './TableHeaderCell';

type Props<T> = React.ComponentProps<typeof TableHeaderCell> & {
    sort?: Sort<T> | null,
    column: keyof T,
    children: React.ReactNode,
    onSort: (s: keyof T) => void
};

const HoverTableHeaderCell = styled(TableHeaderCell)(({ theme }) => ({
    ':hover': {
        cursor: 'pointer',
        backgroundColor: darken(theme.palette.background.paper, 0.1)
    }
}));

const SortableTableHeaderCell = <T extends unknown>({ sort, column, onSort, children, ...rest }: Props<T>) => {
    const showIcon = sort?.column === column;
    const Icon = sort?.descending ? ExpandMoreIcon : ExpandLessIcon;

    const onClick = useCallback(() => onSort(column), [onSort, column]);

    return (
        <HoverTableHeaderCell onClick={onClick} {...rest}>
            {children}
            {showIcon && <Icon sx={{ verticalAlign: 'middle'}} />}
        </HoverTableHeaderCell>
    );
};

export default SortableTableHeaderCell;
