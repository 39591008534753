import { repoFactory } from './base';
import { Brew } from '../models/brew';
import { StageTemplate } from '../models/template';
import { updateDoc, where } from 'firebase/firestore';

const base = repoFactory<Brew>({ collectionName: 'brews' });

const repo = {
  ...base,
  async getBrewsByStyle(style: string) {
    return this.filter(where('style', '==', style));
  },
  async activate(id: string) {
      return await updateDoc(this.getRequiredDoc(id), { active: true });
  },
  async deactivate(id: string) {
    return await updateDoc(this.getRequiredDoc(id), { active: false });
  },
  async updateStages(id: string, stages: StageTemplate[]) {
    return await updateDoc(this.getRequiredDoc(id), { stages });
  },
  async finalize(id: string) {
    return await updateDoc(this.getRequiredDoc(id), { finalized: true });
  },
  async unfinalize(id: string) {
    return await updateDoc(this.getRequiredDoc(id), { finalized: false });
  },
  async publish(id: string) {
    return await updateDoc(this.getRequiredDoc(id), { published: true });
  }
};

export default repo;

export type BrewFilter = {
  active?: boolean,
  published?: boolean,
  ownerId?: string,
  style?: string
};