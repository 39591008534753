import { useCallback, useContext, useEffect, useMemo } from 'react';
import { NotificationsContext } from '../../contexts/NotificationsContext';
import logger from '../../helpers/logger';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import repo from '../../repositories/device-registrations';

const CheckForOtherDevice = () => {
    const { pushMessage, service } = useContext(NotificationsContext);
    const currentUser = useCurrentUser();
    const userId = useMemo(() => currentUser?.userId, [currentUser]);

    const permissionsHandler = useCallback((actionId?: string) => {
        if (!userId || !service || !actionId) {
            return false;
        }

        if (actionId === 'never') {
            service.permissionDenied(userId);
        } else {
            service.requestPermission(userId).then(allowed => {
                if (allowed) {
                    return service.retrieveToken(userId);
                }
            });
        }
    }, [userId, service]);

    useEffect(() => {
        if (!userId || !service) {
            return;
        }

        const currentPermissions = service.currentPermissions(userId);

        if (currentPermissions === 'default') {
            repo.userHasOtherDevice(userId).then(otherDevice => {
                logger.debug('User has other enabled devices?', otherDevice);
                if (otherDevice) {
                    pushMessage({
                        identifier: userId,
                        text: 'We\'ve detected that you have another device with notifications enabled, would you like to receive notifications on this device as well?',
                        severity: 'info',
                        actions: [{
                            id: 'never',
                            text: 'Never'
                        }, {
                            id: 'allow',
                            text: 'Allow'
                        }],
                        handle: permissionsHandler
                    });
                }
            });
        } else if (currentPermissions === 'granted') {
            service.retrieveToken(userId);
        }
    }, [userId, permissionsHandler, pushMessage, service]);

    return <></>;
};

export default CheckForOtherDevice;