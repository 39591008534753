import { repoFactory } from './base';
import { Style } from '../models/style';
import { onSnapshot } from 'firebase/firestore';

const base = repoFactory<Style>({ collectionName: 'styles' });

let cacheById: Partial<Record<string, Style>> = {};
let first = true;
let cachePromise: Promise<void> | null = null;

const repo = {
  ...base,
  populateCache() {
    if (cachePromise) {
      return cachePromise;
    }

    cachePromise = new Promise((resolve, reject) => {
      cacheById = {};
      onSnapshot(this.col(), snapshot => {
        snapshot.docChanges().forEach(change => {
          const style = change.doc.data();
          style.id = change.doc.id;
          
          if (change.type === 'added' || change.type === 'modified') {
            cacheById[style.id] = style;
          } else {
            delete cacheById[style.id];
          }
        });

        if (first) {
          first = false;
          resolve();
        }
      }, reject);
    });

    return cachePromise;
  },
  getOne(id: string) {
    return this.populateCache().then(() => cacheById[id]);
  },
  getMany(ids: string[]) {
    return this.populateCache().then(() => ids.map(id => cacheById[id]));
  },
  getAll() {
    return this.populateCache().then(() => Object.values(cacheById) as Style[]);
  }
};

// Trigger population immediately
repo.populateCache();

export default repo;
