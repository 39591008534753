import { Timestamp } from 'firebase/firestore';
import { WithIndex } from '../helpers/types';
import { Step, fromTemplate as stepFromTemplate } from './step';
import { StageTemplate } from './template';

export type Stage = {
  name: string,
  startTime: Timestamp,
  steps: Step[]
};

export const fromTemplate = (template: StageTemplate, startTime: Timestamp) => {
  let currentTime = startTime;
  const populatedSteps = template.steps.map(step => {
    const s = stepFromTemplate(step, currentTime);
    currentTime = Timestamp.fromMillis(s.startTime.toMillis() + s.duration);

    return s;
  });

  return {
    ...template,
    startTime,
    steps: populatedSteps
  };
};

type StageAndStartTime = {
  stages: Stage[],
  previousEndTime: Timestamp
};

export const fromTemplates = (templates: StageTemplate[], startTime: Timestamp) => {
  return templates.reduce(({stages, previousEndTime}: StageAndStartTime, template: StageTemplate) => {
    const newStage = fromTemplate(template, previousEndTime);
    let nextStartTime = previousEndTime;
    if (newStage.steps.length > 0) {
      const step = newStage.steps.at(-1)!;
      nextStartTime = Timestamp.fromMillis(step.startTime.toMillis() + step.duration);
    }

    const next: StageAndStartTime = { stages: [...stages, newStage], previousEndTime: nextStartTime };
    return next;
  }, { stages: [], previousEndTime: startTime });
};

export const firstIncompleteStep = (stage: Stage): WithIndex<Step> | null => {
  const index = stage.steps.findIndex(s => s.endTime === null);
  if (index >= 0) {
    return {
      index,
      data: stage.steps[index]
    };
  }

  return null;
};

