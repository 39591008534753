import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import BottleTable, { SortableProps } from '../../components/bottles/BottleTable';
import useSort from '../../hooks/useSort';
import { Bottle } from '../../models/bottle';
import { bottlesRepoForBrewFactory } from '../../repositories/bottles';

type Props = {
    brewId: string
};

const BrewBottles = ({ brewId }: Props) => {
    const navigate = useNavigate();
    const [bottles, setBottles] = useState([] as Bottle[]);
    const [loading, setLoading] = useState(false);
    const [sort, onSort] = useSort<SortableProps>();
    const bottlesRepoForBrew = useMemo(() => bottlesRepoForBrewFactory(brewId), [brewId]);
    useEffect(() => {
        setLoading(true);
        bottlesRepoForBrew.getAllPublishedForBrew(sort).then(brewBottles => {
            setBottles(brewBottles);
            setLoading(false);
        });
    }, [bottlesRepoForBrew, sort]);


    return loading ? <LinearProgress /> : (
        <Box sx={{ minWidth: 840, width: '70%' }}>
            <BottleTable bottles={bottles} sort={sort} onSort={onSort} onBottleSelected={b => navigate(`${b.brewdayLink.id}/${b.id}`)} includeBrewdate />
        </Box>
    );
};

export default BrewBottles;
