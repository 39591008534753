import { initializeApp } from 'firebase/app';

export const app = initializeApp({
    apiKey: 'AIzaSyCJmLUZsLy1-0hb52fkti1kvUsP508FDZA',
    authDomain: 'brewday-helper.com',
    databaseURL: 'https://hop-of-the-hill.firebaseio.com',
    projectId: 'hop-of-the-hill',
    storageBucket: 'hop-of-the-hill.appspot.com',
    messagingSenderId: '814162945389',
    appId: '1:814162945389:web:abc00ce2e518261756e664',
    measurementId: 'G-BMQV5YCSPZ'
});