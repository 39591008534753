import { useCallback, useMemo, useState } from 'react';

type Props = {
    rating: number
};

type ReadonlyProps = Props & {
    editable?: false
};

type EditableProps = Props & {
    editable: true,
    onChange: (newRating: number) => void
};

const StarRating = (props: ReadonlyProps | EditableProps) => {
    const rating = props.rating;
    const editable = props.editable;
    const onChange = editable && props.onChange;
    const [xValue, setXValue] = useState<number | null>(null);
    const onMouseMove = useMemo(() => {
        if (editable) {
            return (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                const boundingRect = e.currentTarget.getBoundingClientRect();
                const mouseX = e.clientX - boundingRect.left;

                const ratio = mouseX / boundingRect.width;
                const newX = Math.round(ratio * 10);

                setXValue(newX);
            };
        } else {
            return () => {};
        }
    }, [editable]);

    const onMouseLeave = useMemo(() => {
        if (editable) {
            return () => setXValue(null);
        } else {
            return () => {};
        }
    }, [editable]);

    const onMouseClick = useCallback(() => {
        if (onChange && (xValue != null)) {
            onChange(xValue);
        }
    }, [onChange, xValue]);

    const styles = useMemo(() => {
        if (editable) {
            return {
                cursor: 'pointer'
            };
        } else {
            return {};
        }
    }, [editable]);

    const offset = useMemo(() => (xValue ?? rating) / 10, [xValue, rating]);
    const gradientId = useMemo(() => `bicolored-${rating}`, [rating]);

    return (
        <div onClick={onMouseClick} onMouseMove={onMouseMove} onMouseLeave={onMouseLeave} style={styles}>
            <svg viewBox="0 0 260 80" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <linearGradient id={gradientId}>
                        <stop offset={offset} stopColor="yellow"/>
                        <stop offset={offset} stopColor="white"/>
                    </linearGradient>
                </defs>
                <text fill={`url(#${gradientId})`} transform="matrix(2.55179 0 0 2.73701 -58.704 -68.722)" stroke="#000" x="23.97279" y="47.63464" strokeWidth="0.5" fontSize="24" textAnchor="start" xmlSpace="preserve">★★★★★</text>
            </svg>
        </div>
    );
};

export default StarRating;