import { useCallback, useEffect, useState } from 'react';

import TableCell from '@mui/material/TableCell';

import { Brew } from '../../models/brew';
import SelectableTableRow from '../table/SelectableTableRow';
import stylesRepo from '../../repositories/styles';

type Props = {
    brew: Brew,
    onBrewSelected: (brew: Brew) => void
};

const BrewRow = ({ brew, onBrewSelected }: Props) => {
    const onClick = useCallback(() => onBrewSelected(brew), [onBrewSelected, brew]);
    const [style, setStyle] = useState('');

    useEffect(() => {
        stylesRepo.getOne(brew.style).then(s => setStyle(s?.name || 'N/A'));
    }, [brew.style, setStyle]);

    return (
        <SelectableTableRow onClick={onClick}>
            <TableCell>{brew.name}</TableCell>
            <TableCell>{style}</TableCell>
            <TableCell align="right">{brew.abv}</TableCell>
            <TableCell>{brew.description}</TableCell>
        </SelectableTableRow>
    );
};

export default BrewRow;