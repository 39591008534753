import { Alert } from '@mui/material';
import { ReactNode } from 'react';
import { useCurrentUser } from '../hooks/useCurrentUser';

type Props = {
    children?: ReactNode,
    otherwise?: ReactNode
};

export const NotAllowedAlert = <Alert severity="error">You are not allowed to view this content.</Alert>;

const Authenticated = ({ children, otherwise }: Props) => {
    const user = useCurrentUser();

    const elem = user ? children : otherwise;
    return <>{elem}</>;
};

export default Authenticated;