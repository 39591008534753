import { ThemeProvider } from '@mui/material/styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers';

import Routes from './components/navigation/Routes';
import NotificationContainer from './components/notifications/NotificationContainer';
import CheckForOtherDevice from './components/permissions/CheckForOtherDevice';
import { HeaderActionsContextProvider } from './contexts/HeaderActionsContext';
import { MessagingContextProvider } from './contexts/MessagingContext';
import { NotificationsContextProvider } from './contexts/NotificationsContext';
import { UserContextProvider } from './contexts/UserContext';
import theme from './theme/theme';

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <UserContextProvider>
          <MessagingContextProvider>
            <HeaderActionsContextProvider>
              <DndProvider backend={HTML5Backend}>
                <NotificationsContextProvider>
                  <NotificationContainer />
                  <CheckForOtherDevice />
                </NotificationsContextProvider>
                <Routes />
              </DndProvider>
            </HeaderActionsContextProvider>
          </MessagingContextProvider>
        </UserContextProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
