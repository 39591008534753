import { Timestamp } from 'firebase/firestore';
import { DateTime } from 'luxon';

const isDate = (date: Date | Timestamp): date is Date => {
  return !!(date as Date).getMonth;
};

const formatFunction = (format: string) => (date?: Timestamp | Date) => {
  if (date === undefined) {
    return 'N/A';
  }

  const jsDate = isDate(date) ? date : date.toDate();
  
  return DateTime.fromJSDate(jsDate).toFormat(format);
};

export const dateString = formatFunction('MM/dd/yyyy');
export const dateTimeString = formatFunction('MM/dd/yyyy h:mm a');

export const percentFormat = (n: number) => `${n.toFixed(2)}%`;

