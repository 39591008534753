import { DateTime } from 'luxon';
import { generateKey } from '../../helpers/functions';
import { EventData } from '../types';

export const expectedType = 'brewdayStepNotification';

export type BrewdayStepNotificationData = EventData<typeof expectedType> & {
    stageIndex: number,
    stepIndex: number,
    brewdayId: string,
    brewId: string,
    expectedCompletionTime: DateTime
};

export const identify = (evt: BrewdayStepNotificationData) => generateKey(evt.type, evt.brewId, evt.brewdayId, evt.stageIndex, evt.stepIndex);