// The intented beer results.
// Name of the brew, ABV, flavors, colors
// List of brewdays associated to the brew

import { Populated } from '../helpers/types';
import { Experience, empty as emptyExperience, PopulatedExperience, normalize as normalizeExperience } from './experience';
import { Deletable, Finalizable, Ownable, Publishable } from './owned';
import { Style, normalize as normalizeStyle } from './style';
import { StageTemplate } from './template';

export type Brew = Ownable & Publishable & Deletable & Finalizable & {
  name: string,
  style: string,
  abv: number,
  description: string,
  ratingCount: number,
  averageRating: number,
  experience: Experience,
  stages: StageTemplate[],
  id?: string
};

type PopulatedProperties = {
  style: Style | string,
  experience: PopulatedExperience,
  id: string
};

export type PopulatedBrew = Populated<Brew, PopulatedProperties>;

export type BrewLink = {
  id: string,
  name: string,
  styleId: string
};

export const getLink = (brew: Brew): BrewLink => ({
  id: brew.id || '',
  name: brew.name,
  styleId: brew.style
});

export const empty: PopulatedBrew = {
    name: '',
    style: '',
    abv: 0,
    description: '',
    ratingCount: 0,
    averageRating: 0,
    experience: emptyExperience,
    active: true,
    stages: [],
    ownerId: '',
    published: false,
    id: '',
    finalized: false
};

export const normalize = (pb: PopulatedBrew): Brew => ({
  ...pb,
  style: typeof pb.style === 'string' ? pb.style : normalizeStyle(pb.style),
  experience: normalizeExperience(pb.experience)
});

export const textMatches = (brew: Brew, text: string): boolean => {
  const normalizedSearch = text.toLowerCase();
  return [brew.name, brew.description].some(v => v.indexOf(normalizedSearch) >= 0);
};
