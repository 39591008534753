import { User as FirebaseUser } from 'firebase/auth';

export type User = {
    userId: string
};

export type LoginOptions = {
    password: boolean,
    emailLink: boolean,
    google: boolean,
    none: boolean
};

export const fromFirebase = (firebaseUser: FirebaseUser): User => ({
    userId: firebaseUser.uid
});