import { IconButton, Menu, MenuItem, SvgIcon } from '@mui/material';
import { useState, MouseEvent, useCallback } from 'react';

export type ActionableMenuItem = {
    id: string,
    onClick: (id: string) => void,
    label?: string
};

type Props = {
    items: ActionableMenuItem[],
    icon: typeof SvgIcon
};

const useMenu = ({ items, icon: Icon }: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = !!anchorEl;
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };
    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [setAnchorEl]);

    const button = (
        <IconButton onClick={handleClick}>
            <Icon />
        </IconButton>
    );

    const clickHandler = useCallback((item: ActionableMenuItem) => () => {
        handleClose();
        item.onClick(item.id);
    }, [handleClose]);

    const menu = (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
        >
            {items.map(item => (
                <MenuItem key={item.id} onClick={clickHandler(item)}>{item.label || item.id}</MenuItem>
            ))}
        </Menu>
    );

    return {
        isOpen: open,
        button,
        buttonClick: handleClick,
        menu,
        close: handleClose
    };
};

export default useMenu;