export type Json = string | number | boolean | null | Json[] | { [key: string]: Json };
export const hothPrefix = '__HOTH__';
export const getFullKey = (key: string) => `${hothPrefix}|${key}`;

const saveTo = <T extends Json>(storage: Storage) => (key: string, data: T) => {
    const serialized = JSON.stringify(data);
    storage.setItem(getFullKey(key), serialized);
};

const loadFrom = <T extends Json>(storage: Storage) => (key: string): T | null => {
    const serialized = storage.getItem(getFullKey(key));
    return serialized ? (JSON.parse(serialized) as T) : null;
};

const deleteFrom = (storage: Storage) => (key: string) => {
    storage.removeItem(getFullKey(key));
};

type Store<T extends Json> = {
    save: (key: string, data: T) => void,
    load: (key: string) => T | null,
    delete: (key:string) => void
};

export const getStore = <T extends Json>(storage: Storage): Store<T> => ({
    save: saveTo<T>(storage),
    load: loadFrom<T>(storage),
    delete: deleteFrom(storage)
});

export const getLocal = <T extends Json>() => getStore<T>(window.localStorage);
export const getSession = <T extends Json>() => getStore<T>(window.sessionStorage);
