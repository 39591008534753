import { Alert, Button, Card, CardActions, CardContent, CircularProgress, TextField, Typography } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleRedirect, LoginError } from '../../firebase/auth';

const EmailRedirect = () => {
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState(false);
    const [email, setEmail] = useState('');
    const [emailRequired, setEmailRequired] = useState(false);
    const [isError, setIsError] = useState(false);

    const goHome = useCallback(() => navigate('/'), [navigate]);
    const onEmailChanged = useCallback((e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value), [setEmail]);

    useEffect(() => {
        if (loaded) {
            return;
        }

        setLoaded(true);
        handleRedirect().then(userOrError => {
            if (userOrError === LoginError.EMAIL_REQUIRED) {
                setEmailRequired(true);
            } else if (typeof userOrError === 'object') {
                // Redirect to home page or target URL
                goHome();
            } else {
                // Set error screen
                setIsError(true);
            }
        });
    }, [setEmailRequired, setIsError, goHome, loaded, setLoaded]);

    const onVerifyEmail = useCallback(() => {
        handleRedirect(email).then(userOrError => {
            if (typeof userOrError === 'object') {
                // Redirect to home page or target URL
                setEmailRequired(false);
                goHome();
            } else {
                // Set error screen
                setIsError(true);
            }
        });
    }, [email, setEmailRequired, goHome, setIsError]);

    if (!loaded) {
        return <CircularProgress />;
    }

    if (isError) {
        return (
            <Alert severity="error">An unexpected error has occured while attempting to sign you in. Please try again.</Alert>
        );
    }

    if (!emailRequired) {
        return (
            <Alert severity="success">You have been signed in and will be redirected momentarily.</Alert>
        );
    }

    return (
        <Card variant="outlined" sx={{ maxWidth: 450 }}>
            <CardContent>
                <Typography sx={{ marginBottom: theme => theme.spacing(1) }}>Please enter the e-mail where this link was sent:</Typography>
                <TextField
                fullWidth
                autoFocus
                required
                label="E-mail"
                value={email}
                onChange={onEmailChanged}
                />
            </CardContent>
            <CardActions>
                <Button disabled={!email.length} onClick={onVerifyEmail} variant="contained">Verify</Button>
            </CardActions>
        </Card>
    );
};

export default EmailRedirect;
