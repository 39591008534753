export type StepTemplate = {
    instructions: string,
    duration: number
};

export type StageTemplate = {
    name: string,
    steps: StepTemplate[]
};

export const emptyStage = (): StageTemplate => ({
    name: '',
    steps: []
});

export const emptyStep = (): StepTemplate => ({
    instructions: '',
    duration: 0
});