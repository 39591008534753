
import { Style } from '../../models/style';
import stylesRepo from '../../repositories/styles';
import LazyPicker from './LazyPicker';

type Props = {
    value: Style | null,
    onChange: (style: Style | null) => void
};

const getLabelForStyle = (s: Style | null) => s?.name || '';
const styleCategory = (s: Style | null) => s?.category || '';
const styleComparator = (s1: Style | null, s2: Style | null) => s1?.id === s2?.id;

const getStyles = () => stylesRepo.getAll().then(s => {
    const sortedStyles = [...s];
    sortedStyles.sort((a, b) => {
        if (a.category < b.category) {
            return -1;
        } else if (a.category > b.category) {
            return 1;
        }

        return 0;
    });

    return sortedStyles;
});

const StylePicker = ({ value, onChange }: Props) => {
    return (
        <LazyPicker label="Filter by style" value={value} onChange={onChange} getLabelForOption={getLabelForStyle} getOptions={getStyles} groupBy={styleCategory} areOptionsEqual={styleComparator} />
    );
};

export default StylePicker;