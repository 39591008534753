import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import { empty, normalize, PopulatedBrew } from '../../models/brew';
import { CancelIcon, SaveIcon } from '../../components/icons';
import useActions from '../../hooks/useActions';
import TagList from '../../components/form/TagList';
import { Type } from '../../models/tag';
import tagsRepo from '../../repositories/tags';
import brewRepo from '../../repositories/brews';
import stylesRepo from '../../repositories/styles';
import { getAllTags } from '../../models/experience';
import { useCallback } from 'react';
import LazyPicker from '../../components/form/LazyPicker';
import { getStylePropertyOrId, Style } from '../../models/style';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

type Props = {
    initialBrew: PopulatedBrew
};

const getLabelForStyle = (s: Style | string) => getStylePropertyOrId(s, 'name');
const styleCategory = (s: Style | string) => getStylePropertyOrId(s, 'category');
const styleComparator = (s1: Style | string, s2: Style | string) => getStylePropertyOrId(s1, 'id') === getStylePropertyOrId(s2, 'id');

const getStyles = () => stylesRepo.getAll().then(s => {
    const sortedStyles = [...s];
    sortedStyles.sort((a, b) => {
        if (a.category < b.category) {
            return -1;
        } else if (a.category > b.category) {
            return 1;
        }

        return 0;
    });

    return sortedStyles;
});

const BrewEdit = ({ initialBrew }: Props) => {
    const navigate = useNavigate();
    const goBack = useCallback(() => navigate('..'), [navigate]);
    const formik = useFormik({
        initialValues: initialBrew,
        onSubmit: async (values) => {
            await tagsRepo.ensureTags(getAllTags(values.experience));
            await brewRepo.save(normalize(values));
            goBack();
        }
    });

    useActions([
        {
            label: 'Discard',
            icon: CancelIcon,
            authenticated: true,
            onSelected: goBack
        },
        {
            label: 'Save',
            icon: SaveIcon,
            authenticated: true,
            onSelected: formik.handleSubmit
        }
    ], [formik.handleSubmit]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Typography variant="h3" component="h1">{formik.values.name || 'Brew To Be Named'}</Typography>
            <Stack spacing={2}>
                <TextField label="Name your brew" value={formik.values.name}
                    id="brewName"
                    name="name"
                    onChange={formik.handleChange}
                    error={formik.touched.name && !!formik.errors.name}
                    helperText={formik.touched.name && formik.errors.name}
                    margin="normal"
                    fullWidth
                    required
                />
                <TextField label="Describe your brew (what is on your bottle/can?)" value={formik.values.description}
                    id="description"
                    name="description"
                    onChange={formik.handleChange}
                    error={formik.touched.description && !!formik.errors.description}
                    helperText={formik.touched.description && formik.errors.description}
                    margin="normal"
                    fullWidth
                    required
                />
                <Paper sx={{ padding: theme => theme.spacing(2) }}>
                    <Stack spacing={2}>
                        <Typography variant="subtitle1">The following fields describe qualities that beers of this brew are <i>supposed</i> to have.</Typography>
                        <TextField label="Expected ABV" value={formik.values.abv}
                            id="abv"
                            name="abv"
                            onChange={formik.handleChange}
                            error={formik.touched.abv && !!formik.errors.abv}
                            helperText={formik.touched.abv && formik.errors.abv}
                            margin="normal"
                            fullWidth
                            required
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>
                            }}
                            />
                        <LazyPicker required label="Style" value={formik.values.style} onChange={style => formik.setFieldValue('style', style)} getLabelForOption={getLabelForStyle} getOptions={getStyles} groupBy={styleCategory} areOptionsEqual={styleComparator} />
                        <TagList label="Flavors" values={formik.values.experience.flavors} type={Type.FLAVOR} onChange={tags => formik.setFieldValue('experience.flavors', tags)}/>
                        <TagList label="Mouthfeels" values={formik.values.experience.mouthfeels} type={Type.MOUTHFEEL} onChange={tags => formik.setFieldValue('experience.mouthfeels', tags)}/>
                        <TagList label="Aromas" values={formik.values.experience.aromas} type={Type.AROMA} onChange={tags => formik.setFieldValue('experience.aromas', tags)}/>
                        <TagList label="Appearances" values={formik.values.experience.appearances} type={Type.APPEARANCE} onChange={tags => formik.setFieldValue('experience.appearances', tags)}/>
                    </Stack>
                </Paper>
            </Stack>
        </form>
    );
};

const defaultProps: Props = {
    initialBrew: empty
};

BrewEdit.defaultProps = defaultProps;

export default BrewEdit;