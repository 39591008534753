import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useCallback } from 'react';
import { signOut } from '../../firebase/auth';

type Props = {
    open: boolean,
    onClose: () => void
};

const LogoutDialog = ({ open, onClose }: Props) => {
    const onSignOut = useCallback(() => {
        signOut().then(onClose);
    }, [onClose]);

    return (
        <Dialog open={open}>
            <DialogTitle>Sign Out</DialogTitle>
            <DialogContent>
                <Typography>Are you sure you would like to sign out of your account?</Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>No</Button>
                <Button onClick={onSignOut}>Yes</Button>
            </DialogActions>
        </Dialog>
    );
};

export default LogoutDialog;