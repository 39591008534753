import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import ProfileIcon from '@mui/icons-material/AccountCircle';
import { useState, useContext, useCallback } from 'react';
import { HeaderActionsContext } from '../../contexts/HeaderActionsContext';
import Divider from '@mui/material/Divider';

const HeaderActions = () => {
    const { applicationActions, profileActions } = useContext(HeaderActionsContext);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const closeMenu = useCallback(() => setAnchorEl(null), [setAnchorEl]);
    const menuSelected = useCallback((cb: () => void) => () => {
        closeMenu();
        cb();
    }, [closeMenu]);

    return (
        <>
            {
                applicationActions.map(action => (
                    <Tooltip title={action.label} key={action.label}>
                        <div>
                            <IconButton disabled={action.disabled === true} onClick={action.onSelected} size="large" sx={theme => ({ marginRight: theme.spacing(1) })}>
                                <action.icon fontSize="inherit"/>
                            </IconButton>
                        </div>
                    </Tooltip>
                ))
            }
            {
                (applicationActions.length && <Divider orientation="vertical" flexItem />) || null
            }
            <IconButton size="large" onClick={evt => profileActions.length && setAnchorEl(evt.currentTarget)} sx={theme => ({ marginLeft: theme.spacing(1) })}>
                <ProfileIcon fontSize="inherit" />
            </IconButton>
            <Menu open={!!(anchorEl && profileActions.length)} anchorEl={anchorEl} onClose={closeMenu}>
                {
                    profileActions.map(action => <MenuItem disabled={action.disabled === true} onClick={menuSelected(action.onSelected)} key={action.label}>{action.label}</MenuItem>)
                }
            </Menu>
        </>
    );
};

export default HeaderActions;