import { Timestamp } from 'firebase/firestore';
import { DateTime } from 'luxon';
import { Brewday } from '../models/brewday';
import { fromTemplates } from '../models/stage';
import { getStylePropertyOrId } from '../models/style';
import { forBrew } from '../repositories/brewdays';
import brewRepo from '../repositories/brews';

const service = {
    async startBrewday(brewId: string, userId: string, expectedStartDate: DateTime) {
        const brew = await brewRepo.getOne(brewId);
        if (!brew) {
            throw Error(`Could not find brew with id ${brewId}`);
        }

        const convertedStages = fromTemplates(brew.stages, Timestamp.fromMillis(expectedStartDate.toMillis()));
        const newBrewday: Brewday = {
            batchSize: {
                size: 48,
                unit: 'bottles'
            },
            brewLink: {
                id: brewId,
                name: brew.name,
                styleId: getStylePropertyOrId(brew.style, 'id')
            },
            gravities: [],
            ideas: '',
            notes: [],
            ownerId: userId,
            startDate: Timestamp.fromMillis(expectedStartDate.toMillis()),
            stages: convertedStages.stages,
            readyToServeDate: convertedStages.previousEndTime
        };

        const brewdayRepo = forBrew(brewId);
        return await brewdayRepo.save(newBrewday);
    }
};

export default service;