import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Bottle } from '../../models/bottle';
import BottleRow from './BottleRow';
import SortableTableHeaderCell from '../table/SortableTableHeaderCell';
import { Sort } from '../../repositories/base';
import { useMemo } from 'react';

export type SortableProps = Pick<Bottle, 'servingTime' | 'servingNumber' | 'rating' | 'brewdayLink'>;

type Props = {
    bottles: Bottle[],
    onBottleSelected: (bottle: Bottle) => void,
    sort: Sort<SortableProps> | null,
    includeBrewdate?: boolean,
    onSort: (s: keyof SortableProps) => void
};

const BottleTable = ({ bottles, onBottleSelected, onSort, sort, includeBrewdate }: Props) => {
    const columnWidth = useMemo(() => includeBrewdate ? 20 : 25, [includeBrewdate]);
    return (
        <TableContainer component={Paper}>
            <Table padding="normal">
                <TableHead>
                    <TableRow>
                        <SortableTableHeaderCell sx={{ width: `${columnWidth * 2}%` }} column="servingTime" sort={sort} onSort={onSort}>Time of Consumption</SortableTableHeaderCell>
                        {includeBrewdate && <SortableTableHeaderCell sx={{ width: `${columnWidth}%` }} column="brewdayLink" sort={sort} onSort={onSort}>Brewday Ready At</SortableTableHeaderCell>}
                        <SortableTableHeaderCell sx={{ width: `${columnWidth}%` }} column="servingNumber" sort={sort} onSort={onSort} align="right">Bottle Number</SortableTableHeaderCell>
                        <SortableTableHeaderCell sx={{ width: `${columnWidth}%` }} column="rating" sort={sort} onSort={onSort}>Rating</SortableTableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { bottles.map(bottle => <BottleRow key={bottle.id} bottle={bottle} onBottleSelected={onBottleSelected} includeBrewdate={includeBrewdate} />) }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default BottleTable;