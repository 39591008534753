import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

type Props = {
    open: boolean,
    text: string,
    title?: string,
    confirmText?: string,
    cancelText?: string,
    onCancel: () => void,
    onConfirm: () => void
};

const ConfirmationDialog = ({ text, onCancel, onConfirm, open, title = 'Are you sure?', cancelText = 'Cancel', confirmText = 'Confirm' }: Props) => {
    return (
        <Dialog open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {text}
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>{cancelText}</Button>
                <Button onClick={onConfirm} variant="contained" color="primary">{confirmText}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialog;