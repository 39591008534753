import { MessagePayload } from 'firebase/messaging';

export type MessagePayloadEventData<P> = { [Property in keyof P]: string };

export type MessagePayloadWithType<P> = Omit<MessagePayload, 'data'> & {
    data: Partial<MessagePayloadEventData<P>> & { type?: string }
};

export type ValidatedMessagePayloadWithType<P> = Omit<MessagePayload, 'data'> & {
    data: MessagePayloadEventData<P> & { type?: string }
};

export type EventData<T extends string> = {
    id: string,
    type: T
};

type ValueBasedFilterInput = string | string[] | RegExp;

type MessageFilterInput = ValueBasedFilterInput | ((message: MessagePayloadWithType<any>) => boolean);

const filterValue = (type: string, filterInput: ValueBasedFilterInput): boolean => {
    if (filterInput instanceof RegExp) {
        return filterInput.test(type);
    }
    
    const values = typeof filterInput === 'string' ? [filterInput] : filterInput;
    return values.includes(type);
};

export const filterMessage = <T>(message: MessagePayloadWithType<any>, filter: MessageFilterInput): message is MessagePayloadWithType<T> => {
    if (typeof filter === 'function') {
        return filter(message);
    }

    const messageType = message.data?.type;

    if (!messageType) {
        return false;
    }

    return filterValue(messageType, filter);
};

export type MessageTransformer<P> = {
    filter: MessageFilterInput,
    validate: (message: MessagePayloadWithType<P> | ValidatedMessagePayloadWithType<P>) => message is ValidatedMessagePayloadWithType<P>,
    parse: (message: ValidatedMessagePayloadWithType<P>) => P | null,
};

export type EventTypeFilterInput = ValueBasedFilterInput | ((evt: EventData<string>) => boolean);
export const filterEvent = <T extends EventData<string>>(evt: EventData<string>, filterInput: EventTypeFilterInput): evt is T => {
    if (typeof filterInput === 'function') {
        return filterInput(evt);
    }

    return filterValue(evt.type, filterInput);
};

export type MessageHandler<T> = {
    filter: EventTypeFilterInput,
    handle: (message: T) => void,
};


