import { repoFactory, RepositoryOptions } from './base';
import { Brewday, completeStep } from '../models/brewday';
import { Timestamp } from 'firebase/firestore';
import { Ownable } from '../models/owned';
import { Builder, Converter, ConverterMap } from './filter';

const brewdayRepoFactory = (opts: RepositoryOptions) => {
    const base = repoFactory<Brewday>(opts);
  
    const repo = {
        ...base,
        async completeStep(brewdayId: string, stageIndex: number, stepIndex: number, note?: string) {
            const completionTime = Timestamp.now();
            const brewday = await base.getOne(brewdayId);            
            if (!brewday) {
                throw new Error(`No brewday found with id ${brewdayId}`);
            }
            const newBrewday = completeStep(brewday, stageIndex, stepIndex, note, completionTime);
            await base.save(newBrewday);
        }
    };
  
    return repo;
};

export default brewdayRepoFactory({ collectionName: 'brewdays', isCollectionGroup: true });

export const forBrew = (brewId: string) => brewdayRepoFactory({ collectionName: `brews/${brewId}/brewdays` });


export type BrewdayFilter = Partial<Ownable> & {
    inProgress?: boolean,
    completed?: boolean
};

const inProgressConverter: Converter<Brewday, boolean | undefined> = (val?: boolean) => val ? { key: 'readyToServeDate', operand: '>', value: Timestamp.now() } : null;
const completedConverter: Converter<Brewday, boolean | undefined> = (val?: boolean) => val ? { key: 'readyToServeDate', operand: '<=', value: Timestamp.now() } : null;

export const allConverters: ConverterMap<Brewday, BrewdayFilter> = {
    inProgress: inProgressConverter,
    completed: completedConverter
};

export const createFilterBuilder = (defaults: BrewdayFilter) => new Builder<Brewday, BrewdayFilter>(defaults, allConverters);
