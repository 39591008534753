import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useProfileActions } from '../../hooks/useActions';
import LoginDialog from './LoginDialog';
import LogoutDialog from './LogoutDialog';

const ProfileActionsContainer = () => {
    const navigate = useNavigate();
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const [isLogoutOpen, setIsLogoutOpen] = useState(false);

    const onLoginOpen = useCallback(() => setIsLoginOpen(true), [setIsLoginOpen]);
    const onLogoutOpen = useCallback(() => setIsLogoutOpen(true), [setIsLogoutOpen]);

    const onLoginClose = useCallback(() => setIsLoginOpen(false), [setIsLoginOpen]);
    const onLogoutClose = useCallback(() => setIsLogoutOpen(false), [setIsLogoutOpen]);

    const goToMyAccount = useCallback(() => navigate('/account'), [navigate]);

    useProfileActions([{
        label: 'Log in',
        onSelected: onLoginOpen,
        authenticated: false
    }, {
        label: 'Account',
        onSelected: goToMyAccount,
        authenticated: true
    }, {
        label: 'Log out',
        onSelected: onLogoutOpen,
        authenticated: true
    }], []);

    return (
        <>
        <LoginDialog open={isLoginOpen} onClose={onLoginClose}/>
        <LogoutDialog open={isLogoutOpen} onClose={onLogoutClose}/>
        </>
    );
};

export default ProfileActionsContainer;
