import { Box, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useActions from '../../hooks/useActions';
import Stages from '../../components/stages/Stages';
import { PopulatedBrew } from '../../models/brew';
import { fromTemplate } from '../../models/stage';
import { Timestamp } from 'firebase/firestore';

type Props = {
    brew: PopulatedBrew
};

const BrewdayStages = ({ brew }: Props) => {
    const navigate = useNavigate();
    useActions([{
        label: 'Edit',
        onSelected: () => navigate('edit'),
        icon: EditIcon
    }], [navigate]);
    const stages = useMemo(() => brew.stages.map(s => fromTemplate(s, Timestamp.now())), [brew.stages]);
    return (
        <>
        <Box sx={{ marginBottom: 2 }}>
            <Typography variant="h4" component="h1">{brew.name}</Typography>
        </Box>
        <Stages stages={stages} inProgressStageAndStep={null}/>
        </>
    );
};

export default BrewdayStages;