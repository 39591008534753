import SvgIcon from '@mui/material/SvgIcon';
import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useCurrentUser } from '../hooks/useCurrentUser';

export type Action = {
    label: string,
    authenticated?: boolean,
    onSelected: () => void,
    disabled?: boolean
};

export type ApplicationAction = Action & {
    icon: typeof SvgIcon
};

type Props = {
    children?: ReactNode
};

type Data = {
    applicationActions: ApplicationAction[],
    profileActions: Action[],
    setApplicationActions: Dispatch<SetStateAction<ApplicationAction[]>>,
    setProfileActions: Dispatch<SetStateAction<Action[]>>
};

export const HeaderActionsContext = createContext<Data>({
  applicationActions: [],
  profileActions: [],
  setApplicationActions: () => {},
  setProfileActions: () => {}
});

export const HeaderActionsContextProvider = ({ children }: Props) => {
  const currentUser = useCurrentUser();
  const [allApplicationActions, setApplicationActions] = useState([] as ApplicationAction[]);
  const [allProfileActions, setProfileActions] = useState([] as Action[]);

  const actionFilter = useCallback((action: Action) => {
    const loggedIn = !!currentUser;
    return loggedIn === action.authenticated || (typeof action.authenticated === 'undefined');
  }, [currentUser]);

  const applicationActions = useMemo(() => allApplicationActions.filter(actionFilter), [allApplicationActions, actionFilter]);
  const profileActions = useMemo(() => allProfileActions.filter(actionFilter), [allProfileActions, actionFilter]);

  return (
    <HeaderActionsContext.Provider value={{ applicationActions, setApplicationActions, profileActions, setProfileActions }}>
      { children || null }
    </HeaderActionsContext.Provider>
  );
};
