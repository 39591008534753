import { useEffect, useMemo, useState } from 'react';
import { Experience, getAllTags, PopulatedExperience, populateExperience } from '../models/experience';
import { Tag } from '../models/tag';
import tagRepo from '../repositories/tags';

const usePopulatedExperience = (basicExperience?: Experience): PopulatedExperience | null => {
    const [allTags, setTags] = useState({} as Partial<Record<string, Tag>>);
    const allTagIds = useMemo(() => basicExperience && getAllTags(basicExperience), [basicExperience]);
    useEffect(() => {
        if (allTagIds) {
            tagRepo
            .getManyByIds(allTagIds)
            .then(allTags => allTags.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}))
            .then(groupedTags => setTags(groupedTags));
        }
    }, [allTagIds]);

    return basicExperience ? populateExperience(basicExperience, allTags) : null;
};

export default usePopulatedExperience;