import { useEffect, useState } from 'react';
import logger from '../helpers/logger';
import { Style } from '../models/style';
import styleRepo from '../repositories/styles';

const usePopulatedStyle = (styleId?: string): Style | null => {
    const [style, setStyle] = useState<Style | null>(null);
    useEffect(() => {
        if (styleId) {
            styleRepo.getOne(styleId).then(s => {
                if (s) {
                    setStyle(s);
                } else {
                    logger.warn('No style found with id ', styleId);
                }
            });
        }
    }, [styleId]);

    return style;
};

export default usePopulatedStyle;