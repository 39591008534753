import { DependencyList, useContext, useEffect, useMemo } from 'react';
import { MessagingContext } from '../contexts/MessagingContext';
import { EventTypeFilterInput, MessageHandler } from '../messaging/types';

type Callback<T> = (data: T) => void;

const useMessage = <T>(verify: EventTypeFilterInput, callback: Callback<T>, deps: DependencyList = []) => {
    const {
        subscribe,
        unsubscribe
    } = useContext(MessagingContext);

    const handler: MessageHandler<T> = useMemo(() => ({
        filter: verify,
        handle: callback
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [verify, callback, ...deps]);

    useEffect(() => {
        const id = subscribe(handler);

        return () => unsubscribe(id);
    }, [handler, subscribe, unsubscribe]);
};

export default useMessage;