import { ListItemButton } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useState, useCallback, useMemo } from 'react';
import { dateString, dateTimeString } from '../../helpers/format';
import { StageAndStep } from '../../models/brewday';
import { Stage } from '../../models/stage';
import { ExpandMoreIcon, ExpandLessIcon } from '../icons';
import StepRow from './StepRow';

type Props = {
    stage: Stage,
    stageIndex: number,
    inProgressStageAndStep: StageAndStep | null
};

const StageRow = ({ stage, stageIndex, inProgressStageAndStep }: Props) => {
    const [collapsed, setCollapsed] = useState(true);
    const toggleCollapsed = useCallback(() => setCollapsed(c => !c), [setCollapsed]);
    const CollapseIcon = collapsed ? ExpandMoreIcon : ExpandLessIcon;
    const timestamp = useMemo(() => dateString(stage.startTime), [stage.startTime]);
    const backgroundColor = useMemo(() => (inProgressStageAndStep === null || inProgressStageAndStep.stageIndex < stageIndex) ? 'inherit' : inProgressStageAndStep.stageIndex === stageIndex ? 'steps.inProgress.main' : 'steps.complete.main', [inProgressStageAndStep, stageIndex]);
    const inProgressStep = useMemo(() => inProgressStageAndStep === null || inProgressStageAndStep.stageIndex < stageIndex ? null : inProgressStageAndStep.stageIndex > stageIndex ? true : { data: inProgressStageAndStep.step, index: inProgressStageAndStep.stepIndex }, [inProgressStageAndStep, stageIndex]);

    return (
        <>
            <ListItem>
                <ListItemButton sx={{ backgroundColor }} onClick={toggleCollapsed}>
                    <ListItemText primary={stage.name} secondary={timestamp} />
                    <ListItemIcon>
                        <CollapseIcon />
                    </ListItemIcon>
                </ListItemButton>
            </ListItem>
            <Collapse in={!collapsed}>
                {
                    stage.steps.map((step, stepIndex) => <StepRow key={dateTimeString(step.startTime)} step={step} stepIndex={stepIndex} stageIndex={stageIndex} inProgressStep={inProgressStep} />)
                }
            </Collapse>
        </>
    );
};

export default StageRow;