// For recording notes about specific beers within a batch.

import { Experience, PopulatedExperience, normalize as normalizeExperience, copy } from './experience';
import { BrewdayLink, getLink, PopulatedBrewday } from './brewday';
import { Populated } from '../helpers/types';
import { Timestamp } from 'firebase/firestore';
import { Publishable } from './owned';

export type Bottle = Publishable & {
  experience: Experience,
  rating: number,
  servingNumber: number,
  notes: string,
  servingTime: Timestamp,
  brewdayLink: BrewdayLink,
  consumerId: string,
  id?: string
};

type PopulatedProperties = {
  experience: PopulatedExperience,
  brewday: PopulatedBrewday,
  id: string
};

export type PopulatedBottle = Populated<Bottle, PopulatedProperties>;

export const empty = (brewday: PopulatedBrewday): PopulatedBottle => ({
  experience: copy(brewday.brew.experience),
  rating: 0,
  servingNumber: 0,
  notes: '',
  servingTime: Timestamp.fromDate(new Date()),
  brewdayLink: getLink(brewday),
  brewday,
  id: '',
  consumerId: '',
  published: false
});

export const normalize = (bottle: PopulatedBottle): Bottle => ({
  ...bottle,
  experience: normalizeExperience(bottle.experience)
});
