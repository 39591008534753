import { useCallback, useMemo, useState } from 'react';
import { DragSourceMonitor } from 'react-dnd';
import dragTypes from '../../drag-types';
import { StepTemplate } from '../../models/template';
import ResortableTableBody, { Index } from '../table/ResortableTableBody';
import StepTemplateRow from './StepTemplateRow';

type Props = {
    initialSteps: StepTemplate[],
    id: string
};

type DragProps = { isDragging: boolean };

const StepTemplates = ({ initialSteps, id }: Props) => {
    const dragType = useMemo(() => `${dragTypes.STEP}-${id}`, [id]);
    const identifiedSteps = useMemo(() => (initialSteps || []), [initialSteps]);

    const [indexes, setIndexes] = useState(identifiedSteps.map((_, idx) => idx));
    const [steps, setSteps] = useState<(StepTemplate | null)[]>(identifiedSteps);
    const deleteStep = useCallback((index: number) => () => {
        setSteps(s => [...s.slice(0, index), null, ...s.slice(index + 1)]);
    }, [setSteps]);
    const updateStep = useCallback((index: number) => (template: StepTemplate) => {
        setSteps(s => [...s.slice(0, index), template, ...s.slice(index + 1)]);
    }, [setSteps]);

    const renderRow = useCallback(({ index }: Index, drag: DragProps) => {
        const step = steps[index];
        return step && <StepTemplateRow step={step} isDragging={drag.isDragging} onDelete={deleteStep(index)} onUpdate={updateStep(index)}/>;
    }, [deleteStep, updateStep, steps]);

    const collect = useCallback((monitor: DragSourceMonitor<number, void>) => ({ isDragging: !!monitor.isDragging() }), []);
    
    return (
        <ResortableTableBody itemType={dragType} indexes={indexes} onRenderRow={renderRow} onCollect={collect} onReorder={setIndexes}/>
    );
};

export default StepTemplates;