import { Timestamp } from 'firebase/firestore';
import { WithIndex } from './types';

export const identity = (x: any) => x;
export const exists = (x: any): x is object => !!x;

export const maxBy = <T, V>(values: T[], orderBy: (data: T) => V) => {
    return values.reduce((a, b) => {
        const orderByA = orderBy(a);
        const orderByB = orderBy(b);
        
        if (orderByA > orderByB) {
            return b;
        } else {
            return a;
        }
    });
};
export const max = (nums: number[]) => maxBy(nums, identity);
export const neverGetHere = (_: never) => {};

export const deepClone = <T>(obj: T): T => {
    let retVal: unknown;
    if (obj === null) {
        retVal = null;
    } else if (Array.isArray(obj)) {
        retVal = obj.map(deepClone);
    } else if (obj instanceof Timestamp) {
        retVal = Timestamp.fromMillis(obj.toMillis());
    } else if (typeof obj === 'object') {
        retVal = Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, deepClone(value)]));
    } else {
        retVal = obj;
    }

    return retVal as T;
};

export const firstSuccessful = <IN, OUT>(elems: IN[], map: ((x: IN) => OUT | null)): WithIndex<OUT> | null => {
    return elems.reduce((acc: WithIndex<OUT> | null, cur: IN, index: number) => {
        if (acc) {
            return acc;
        }

        const mapped = map(cur);
        if (mapped) {
            return {
                data: mapped,
                index
            };
        }

        return null;
    }, null);
};

export const convertToNumber = (s?: string): number | null => {
    return typeof s === 'undefined' ? null : Number.parseInt(s);
};

export const generateKey = (...params: any[]) => params.join('$');

export const zip = <L, R>(left: L[], right: R[]): [L, R][] => {
    const smallerLength = Math.min(left.length, right.length);
    return left.slice(0, smallerLength).map((leftValue, index) => [leftValue, right[index]]);
};
