import { BrewdayStepNotificationData, expectedType } from './event';
import { MessagePayloadEventData, MessagePayloadWithType, MessageTransformer, ValidatedMessagePayloadWithType } from '../types';
import { DateTime } from 'luxon';

const isValidData = (data: Partial<MessagePayloadEventData<BrewdayStepNotificationData>>): boolean => {
    return !!(data.brewId && data.brewdayId && data.stageIndex && data.stepIndex && data.expectedCompletionTime);
};

const transformer: MessageTransformer<BrewdayStepNotificationData> = {
    filter: expectedType,
    parse: function (message: ValidatedMessagePayloadWithType<BrewdayStepNotificationData>): BrewdayStepNotificationData | null {
        return {
            ...message.data,
            type: expectedType,
            stageIndex: parseInt(message.data.stageIndex, 10),
            stepIndex: parseInt(message.data.stepIndex, 10),
            expectedCompletionTime: DateTime.fromISO(message.data.expectedCompletionTime)
        };
    },
    validate: function (message: MessagePayloadWithType<BrewdayStepNotificationData> | ValidatedMessagePayloadWithType<BrewdayStepNotificationData>): message is ValidatedMessagePayloadWithType<BrewdayStepNotificationData> {
        return isValidData(message.data);
    }
};

export default transformer;