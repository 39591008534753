import { IconButton } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useState, useCallback, useMemo } from 'react';
import useMenu, { ActionableMenuItem } from '../../hooks/useMenu';
import { StageTemplate, StepTemplate } from '../../models/template';
import { ExpandMoreIcon, ExpandLessIcon, DraggableIcon as DragIcon, ListItemMenuIcon } from '../icons';
import StageTemplateDialog from './StageTemplateDialog';
import StepTemplateDialog from './StepTemplateDialog';
import StepTemplates from './StepTemplates';

type Props = {
    stage: StageTemplate,
    isDragging: boolean,
    onDelete: () => void,
    onUpdate: (tmpl: StageTemplate) => void,
    id: string
};

const StageTemplateRow = ({ stage, isDragging, onDelete, onUpdate, id }: Props) => {
    const [collapsed, setCollapsed] = useState(true);
    const toggleCollapsed = useCallback(() => setCollapsed(c => !c), [setCollapsed]);
    const CollapseIcon = collapsed ? ExpandMoreIcon : ExpandLessIcon;
    const [isUpdating, setIsUpdating] = useState(false);
    const startUpdating = useCallback(() => setIsUpdating(true), [setIsUpdating]);
    const stopUpdating = useCallback(() => setIsUpdating(false), [setIsUpdating]);
    const finalize = useCallback((tmpl: StageTemplate) => {
        onUpdate(tmpl);
        stopUpdating();
    }, [onUpdate, stopUpdating]);

    const [newStepOpen, setNewStepOpen] = useState(false);
    const startNewStep = useCallback(() => setNewStepOpen(true), [setNewStepOpen]);
    const closeNewStep = useCallback(() => setNewStepOpen(false), [setNewStepOpen]);
    const saveNewStep = useCallback((template: StepTemplate) => {
        onUpdate({
            ...stage,
            steps: [...stage.steps, template]
        });
        closeNewStep();
    }, [onUpdate, stage, closeNewStep]);

    const menuItems: ActionableMenuItem[] = useMemo(() => [{ id: 'Delete', onClick: onDelete }, { id: 'Edit', onClick: startUpdating }, { id: 'Add step', onClick: startNewStep }], [onDelete, startUpdating, startNewStep]);
    const { menu, buttonClick } = useMenu({ icon: ListItemMenuIcon, items: menuItems });

    return (
        <div style={{ opacity: isDragging ? 0.5 : 1, cursor: 'grab' }}>
            <ListItemButton
            button
            onClick={toggleCollapsed}
            >
                <ListItemIcon>
                    <IconButton edge="start" onClick={buttonClick}>
                        <ListItemMenuIcon />
                    </IconButton>
                </ListItemIcon>
                <ListItemText primary={stage.name} />
                <ListItemIcon>
                    <CollapseIcon />
                </ListItemIcon>
                <ListItemIcon>
                    <DragIcon />
                </ListItemIcon>
            </ListItemButton>
            <Collapse in={!collapsed} sx={{ marginLeft: theme => theme.spacing(5), marginRight: theme => theme.spacing(5) }}>
                <StepTemplates initialSteps={stage.steps} id={id} />
            </Collapse>
            <StageTemplateDialog template={stage} open={isUpdating} onSave={finalize} onCancel={stopUpdating} />
            <StepTemplateDialog fullWidth maxWidth="sm" open={newStepOpen} onCancel={closeNewStep} onSave={saveNewStep} />
            {menu}
        </div>
    );
};

export default StageTemplateRow;