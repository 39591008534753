import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Brew } from '../../models/brew';
import BrewRow from './BrewRow';
import TableHeaderCell from '../table/TableHeaderCell';

type Props = {
    brews: Brew[],
    onBrewSelected: (brew: Brew) => void
};

const BrewTable = ({ brews, onBrewSelected }: Props) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>Name</TableHeaderCell>
                        <TableHeaderCell>Style</TableHeaderCell>
                        <TableHeaderCell align="right">ABV</TableHeaderCell>
                        <TableHeaderCell>Description</TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { brews.map(brew => <BrewRow key={brew.id} brew={brew} onBrewSelected={onBrewSelected} />) }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default BrewTable;