import { useEffect, useMemo, useState } from 'react';
import { Bottle, PopulatedBottle } from '../models/bottle';
import { Brewday } from '../models/brewday';
import { forBrew } from '../repositories/brewdays';
import usePopulatedBrewday from './usePopulatedBrewday';
import usePopulatedExperience from './usePopulatedExperience';

const usePopulatedBottle = (basicBottle?: Bottle | null): PopulatedBottle | null => {
    const brewId = basicBottle?.brewdayLink.brewId;
    const brewdayId = basicBottle?.brewdayLink.id;

    const [brewday, setBrewday] = useState<Brewday | null>(null);
    const brewdayRepo = useMemo(() => brewId ? forBrew(brewId) : null, [brewId]);
    useEffect(() => {
        if (brewdayId && brewdayRepo) {
            brewdayRepo.getOne(brewdayId).then(b => setBrewday(b));
        }
    }, [brewdayId, brewdayRepo]);

    const populatedBrewday = usePopulatedBrewday(brewday);
    const populatedExperience = usePopulatedExperience(basicBottle?.experience);

    const populatedBottle = useMemo(() => {
        if (!basicBottle || !populatedBrewday || !populatedExperience) {
            return null;
        }

        const pb: PopulatedBottle = {
            ...basicBottle,
            experience: populatedExperience,
            brewday: populatedBrewday,
            id: basicBottle.id!
        };

        return pb;
    }, [basicBottle, populatedExperience, populatedBrewday]);

    return populatedBottle;
};

export default usePopulatedBottle;