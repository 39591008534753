import { IconButton, Menu, TextField, Toolbar, Typography } from '@mui/material';
import { ChangeEvent, ReactNode, useCallback, useMemo, useState } from 'react';
import { filterIcon as FilterIcon } from '../icons';

type BaseProps = {
    tableName: string,
    children?: ReactNode,
    menuItems: ReactNode[]
};

type TextSearchProps = BaseProps & {
    searchText: string,
    onSearchTextChanged: (e: ChangeEvent<HTMLInputElement>) => void
};

type Props = BaseProps | TextSearchProps;

const TableFilterToolbar = ({ tableName, children, menuItems, ...textSearch }: Props) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = useCallback((e) => setAnchorEl(e.currentTarget), [setAnchorEl]);
    const closeMenu = useCallback(() => setAnchorEl(null), [setAnchorEl]);
    const textElement = useMemo(() => {
        if ('searchText' in textSearch) {
            const { searchText, onSearchTextChanged } = textSearch;
            return (
                <TextField
                value={searchText}
                placeholder="Search..."
                onChange={onSearchTextChanged}
                sx={{ marginLeft: theme => theme.spacing(1), marginRight: theme => theme.spacing(1) }}
                />
            );
        }

        return null;
    }, [textSearch]);

    const isMenuOpen = anchorEl !== null;

    return (
        <>
        <Toolbar disableGutters>
            <Typography color="inherit" variant="h5" sx={{ flex: '1 1 100%' }}>{tableName}</Typography>
            {children}
            {textElement}
            <IconButton onClick={openMenu}>
                <FilterIcon />
            </IconButton>
        </Toolbar>
        <Menu
            id={`${tableName}-filter`}
            anchorEl={anchorEl}
            open={isMenuOpen}
            onClose={closeMenu}
        >
            {menuItems}
        </Menu>
        </>
    );
};

export default TableFilterToolbar;