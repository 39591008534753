import { Box, CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useEffect, useMemo, useState } from 'react';
import QRCode from 'qrcode';
import Field from '../Field';
import StarRating from '../StarRating';
import { Tag } from '../../models/tag';
import { PopulatedBottle } from '../../models/bottle';
import { dateTimeString } from '../../helpers/format';
import config from '../../config';
import logger from '../../helpers/logger';

type Props = {
    bottle: PopulatedBottle
};

const join = (array: string[] = []) => array.join(', ') || 'None (yet)';
const joinTags = (tags: Tag[]) => join(tags.map(tag => tag.value));

const getUrlForBottle = (bottleId: string, brewId: string) => {
    return `${config.app.baseUrl}/brews/${brewId}/beers/${bottleId}`;
};

const BrewBottleDetails = ({ bottle }: Props) => {
    const [qrData, setQrData] = useState('');
    useEffect(() => {
        const url = getUrlForBottle(bottle.id, bottle.brewdayLink.brewId);
        QRCode.toDataURL(url).then(setQrData).catch(e => logger.error('Failed to make qr code:', e));
    }, [bottle.id, bottle.brewdayLink]);

    const qrDataElement = useMemo(() => {
        return qrData ? <img src={qrData} /> : <CircularProgress size="small" />;
    }, [qrData]);
    return (
        <>
            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h4" component="h1">{bottle.brewday.brew.name}</Typography>
                <Typography variant="subtitle1">(Serving number {bottle.servingNumber})</Typography>
            </Box>
            <Box sx={{ maxWidth: 250 }}>
                <StarRating rating={bottle.rating} />
            </Box>
            <Field label="Consumed At" value={dateTimeString(bottle.servingTime)} />
            <Field label="Flavors" value={joinTags(bottle.experience.flavors)} />
            <Field label="Appearances" value={joinTags(bottle.experience.appearances)} />
            <Field label="Aromas" value={joinTags(bottle.experience.aromas)} />
            <Field label="Mouthfeels" value={joinTags(bottle.experience.mouthfeels)} />
            <Field label="Additional Notes" value={bottle.notes} />
            {qrDataElement}
        </>
    );
};

export default BrewBottleDetails;