import { defaultWindowOpener } from '../helpers/contextual-functions';
import { firstSuccessful } from '../helpers/functions';
import { processors } from './all-processors';
import { transformers as transformersAsObject } from './transformers';
import { EventData, filterEvent, filterMessage, MessageHandler, MessagePayloadWithType, MessageTransformer } from './types';

const transformers = Object.values(transformersAsObject);

type MessagePayloadType<C> = C extends MessageTransformer<infer T> ? T : unknown;

export const transform = (msg: MessagePayloadWithType<any>): EventData<string> | null => {
    const transformed = firstSuccessful(transformers, t => (filterMessage<MessagePayloadType<typeof t>>(msg, t.filter) && t.validate(msg) && t.parse(msg)) || null);
    return transformed && transformed.data;
};

export const getHandler = (allHandlers: MessageHandler<any>[]) => (evt: EventData<string>) => {
    allHandlers.forEach(handler => {
        if (filterEvent(evt, handler.filter)) {
            handler.handle(evt);
        }
    });
};

interface GenericMessagePayload {
    data?: Record<string, string>;
}

export const isValidMessage = <T>(payload: GenericMessagePayload): payload is MessagePayloadWithType<T> => {
    if (!payload.data) {
        return false;
    }

    if (!payload.data.type) {
        return false;
    }

    return true;
};

export const processNotification = <K extends string, T extends EventData<K>>(type: K, data: T, action?: string, openWindow = defaultWindowOpener) => {
    const processor = processors[type];
    if (processor) {
        return processor.process(data, openWindow, action);
    }
};