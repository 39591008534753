import { useEffect, useContext, DependencyList } from 'react';
import { Action, ApplicationAction, HeaderActionsContext } from '../contexts/HeaderActionsContext';

const useActions = (actions: ApplicationAction[], deps: DependencyList) => {
    const { setApplicationActions } = useContext(HeaderActionsContext);
    useEffect(() => {
        setApplicationActions(actions);

        return () => setApplicationActions([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps]);
};

export const useProfileActions = (actions: Action[], deps: DependencyList) => {
    const { setProfileActions } = useContext(HeaderActionsContext);
    useEffect(() => {
        setProfileActions(actions);

        return () => setProfileActions([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [...deps]);
};

export default useActions;