import { Route, Routes } from 'react-router-dom';
import EmailRedirect from './EmailRedirect';

const AuthRedirectRoutes = () => {
    return (
        <Routes>
            <Route path="signin" element={<EmailRedirect />} />
        </Routes>
    );
};

export default AuthRedirectRoutes;
