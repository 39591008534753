import { Stack, Toolbar } from '@mui/material';
import { useContext, useMemo } from 'react';
import { processNotification } from '../../messaging';
import Notification from './Notification';
import { Message, NotificationsContext } from '../../contexts/NotificationsContext';

const getDefaultHandler = (msg: Message<string>) => (actionId?: string) => 'originalEventData' in msg && processNotification(msg.originalEventData.type, msg.originalEventData, actionId);

const NotificationContainer = () => {
    const { messages, removeMessage } = useContext(NotificationsContext);
    const messagesWithCallbacks = useMemo(() => messages.map(m => 'handle' in m ? { ...m } : { ...m, handle: getDefaultHandler(m) }), [messages]);
    return (
        <Stack sx={{ position: 'fixed', width: '30%', minWidth: 250, right: 0, top: 0, zIndex: theme => theme.zIndex.snackbar + 1, visibility: 'hidden' }}>
            <Toolbar />
            <Stack sx={{ height: 'auto', justifyContent: 'space-between', alignItems: 'stretch', visibility: 'visible' }}>
            {
                messagesWithCallbacks.map(msg => <Notification key={msg.identifier} message={msg.text} actions={msg.actions} severity={msg.severity} onClose={() => removeMessage(msg.identifier)} onClick={msg.handle} />)
            }
            </Stack>
        </Stack>
    );
};

export default NotificationContainer;