type ContextCallbacks<T> = {
    serviceWorker: (context: ServiceWorkerGlobalScope) => T,
    application: (context: Window) => T
};

type OptionalContextCallbacks<T> = Partial<ContextCallbacks<T>> & {
    default: () => T
};

const hasApplicationCallback = <T>(callbacks: OptionalContextCallbacks<T> | ContextCallbacks<T>): callbacks is ContextCallbacks<T> => {
    return !!callbacks.application;
};

const hasServiceWorkerCallback = <T>(callbacks: OptionalContextCallbacks<T> | ContextCallbacks<T>): callbacks is ContextCallbacks<T> => {
    return !!callbacks.serviceWorker;
};

export const withContext = <T>(callbacks: OptionalContextCallbacks<T> | ContextCallbacks<T>): T => {
    /* eslint-disable no-restricted-globals */
    if (typeof ServiceWorkerGlobalScope !== 'undefined' && self instanceof ServiceWorkerGlobalScope) {
        if (hasServiceWorkerCallback(callbacks)) {
            return callbacks.serviceWorker(self);
        } else {
            return callbacks.default();
        }
    /* eslint-enable no-restricted-globals */
    } else {
        if (hasApplicationCallback(callbacks)) {
            return callbacks.application(window);
        } else {
            return callbacks.default();
        }
    }
};
