import { useState, useEffect, useCallback, useMemo } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { newTag, Tag, Type } from '../../models/tag';
import tagRepo from '../../repositories/tags';

type Props<T extends Tag> = {
    label: string,
    type: Type,
    values: (T | string)[],
    onChange: (tags: T[]) => void
};

const getLabelFromTag = (tag: Tag | string) => typeof tag === 'string' ? `Add new option: '${tag}'` : tag.value;
const identity = (x: any) => x;

const TagList = <T extends Tag>({
    label,
    type,
    values,
    onChange
}: Props<T>) => {
    type OptionType = string | T;
    const [searchText, setSearchText] = useState('');
    const [options, setOptions] = useState([] as T[]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let current = true;
        if (searchText) {
            setLoading(true);
            tagRepo.findTags(type, searchText).then(opts => {
                if (current) {
                    setOptions(opts as T[]); // Filtering by type
                    setLoading(false);
                }
            });
        } else {
            setOptions([]);
            setLoading(false);
        }

        return () => {
            current = false;
        };
    }, [searchText, setOptions, type, setLoading]);

    const handleSelect = useCallback((_, newValues: OptionType[]) => {
        onChange(newValues.map(tag => typeof tag === 'string' ? newTag({ type, value: tag }) as T : tag));
    }, [onChange, type]);

    const optionsList = useMemo(() => {
        if (searchText) {
            return [...options, ...values, searchText];
        }

        return [...options, ...values];
    }, [searchText, options, values]);

    const isTagSelected = useCallback((option: OptionType, value: OptionType) => {
        if (typeof option === 'string' && typeof value === 'string') {
            return option === value;
        }

        if (typeof option === 'string' || typeof value === 'string') {
            return false;
        }

        return option.id === value.id;
    }, []);

    return (
        <Autocomplete
        multiple
        fullWidth
        value={values}
        options={optionsList}
        loading={loading}
        getOptionLabel={getLabelFromTag}
        onInputChange={(_, input) => setSearchText(input)}
        onChange={handleSelect}
        filterOptions={identity}
        isOptionEqualToValue={isTagSelected}
        filterSelectedOptions
        noOptionsText="Start typing to see available results"
        renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={label}
            />
        )}
        />
    );
};

export default TagList;