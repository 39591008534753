import { getMessaging, getToken } from 'firebase/messaging';
import { app } from '../../firebase/app';
import config from '../../config';
import repo from '../../repositories/device-registrations';
import { getLocal } from '../../services/storage';
import logger from '../../helpers/logger';

export const messaging = getMessaging(app);

type NotificationToken = {
    allowed: true,
    token: string
};

type NotificationPermissionRequired = {
    allowed: false
};

export type NotificationPermissions = NotificationToken | NotificationPermissionRequired;

const localStoragePrefix = 'hoth-bh-notif-perm-';
const neverAllow = 'never-allow';
const localStore = getLocal<typeof neverAllow>();

export const getNotificationsService = (swRegistration: ServiceWorkerRegistration) => ({
    retrieveToken(userId: string): Promise<NotificationPermissions> {
        return getToken(messaging, { ...config.messaging, serviceWorkerRegistration: swRegistration }).catch(() => null).then(token => {
            logger.debug('Token from FCM for user', userId, 'was', token);
            if (token) {
                return repo.registerDevice(userId, token).then(() => (
                    {
                        allowed: true,
                        token
                    }
                ));
            } else {
                return {
                    allowed: false
                };
            }
        });
    },
    permissionDenied(userId: string) {
        localStore.save(`${localStoragePrefix}${userId}`, neverAllow);
    },
    requestPermission(userId: string): Promise<boolean> {
        const alreadyDenied = this.currentPermissions(userId);
        if (alreadyDenied === 'denied') {
            logger.debug('User has denied receiving notifications');
            return Promise.resolve(false);
        }

        logger.debug('Requesting permission for notifications from user', userId);
        return Notification.requestPermission().then(p => {
            logger.debug('User response:', p);
            if (p === 'denied') {
                this.permissionDenied(userId);
            }

            return p;
        }).then(permission => permission === 'granted');
    },
    currentPermissions(userId: string): NotificationPermission {
        const alreadyDenied = localStore.load(`${localStoragePrefix}${userId}`);
        if (alreadyDenied === neverAllow) {
            logger.debug('User has requested never to receive notifications');
            return 'denied';
        }

        return Notification.permission;
    }
});