import LinearProgress from '@mui/material/LinearProgress';
import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import BottleDetails from '../../components/bottles/BottleDetails';
import usePopulatedBottle from '../../hooks/usePopulatedBottle';
import { Bottle } from '../../models/bottle';
import { PopulatedBrewday } from '../../models/brewday';
import bottleRepoFactory from '../../repositories/bottles';

type RoutedProps = {
    bottleId: string
};

type SuppliedProps = {
    brewday: PopulatedBrewday
};

type Props = RoutedProps & SuppliedProps;

const BrewdayBottleDetails = ({ brewday, bottleId }: Props) => {
    const [bottle, setBottle] = useState<Bottle | null>(null);
    const repo = useMemo(() => bottleRepoFactory(brewday.brew.id, brewday.id), [brewday.brew.id, brewday.id]);
    useEffect(() => {
        repo.getOne(bottleId).then(b => setBottle(b));
    }, [repo, bottleId]);

    const populatedBottle = usePopulatedBottle(bottle);

    if (!populatedBottle) {
        console.log('Waiting for populated bottle.');
    }

    return !populatedBottle ? <LinearProgress /> : (
        <BottleDetails bottle={populatedBottle} />
    );
};

export const RoutedBrewdayBottleDetails = ({ brewday }: SuppliedProps) => {
    const { bottleId } = useParams<RoutedProps>();
    if (!bottleId) {
        return null;
    }

    return <BrewdayBottleDetails brewday={brewday} bottleId={bottleId} />;
};

export default BrewdayBottleDetails;
