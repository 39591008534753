import { Button, Dialog, List, ListItem, ListItemText, ListSubheader, Stack, Typography } from '@mui/material';
import { Fragment, MouseEventHandler, useMemo, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { dateTimeString } from '../../helpers/format';
import { fromIdentifierToIndex, getLatestNote, Identifier, Step } from '../../models/step';
import { CompleteCheckmark, SkipNoSign } from '../../components/icons';
import logger from '../../helpers/logger';
import CompleteStepForm from '../../components/stages/progress/CompleteStepForm';
import { forBrew } from '../../repositories/brewdays';
import { PopulatedBrewday } from '../../models/brewday';

type Props = {
    brewday: PopulatedBrewday
};

type RouteParams = {
    defaultStepIdentifier?: Identifier,
    defaultAction?: string
};

type StepAndStage = {
    step: Step,
    stageName: string,
    isPrimary: boolean
};

const addToMap = (map: Map<string, StepAndStage[]>) => (stepAndStage: StepAndStage | null) => {
    if (stepAndStage) {
        const key = stepAndStage.stageName.toLowerCase();
        const existing = map.get(key) || [];
    
        map.set(key, [...existing, stepAndStage]);
    }
};

const getDefaultNotes = (action?: string): string | undefined => {
    if (action === 'skip') {
        return 'Skipped this step!';
    }
};

const ignoreOpacity = 0.57;

const getListItem = (stageStep: StepAndStage, lastStep: boolean, onComplete: MouseEventHandler<HTMLButtonElement>, onSkip: MouseEventHandler<HTMLButtonElement>) => {
    if (stageStep.isPrimary) {
        return (
            <ListItem divider={lastStep} key={dateTimeString(stageStep.step.startTime)}>
                <ListItemText inset primary={stageStep.step.instructions} secondary={getLatestNote(stageStep.step)?.notes} />
                <Stack direction="row" spacing={3}>
                    <Button onClick={onComplete} variant="contained" endIcon={<CompleteCheckmark />} color="success">
                        Complete
                    </Button>
                    <Button onClick={onSkip} variant="contained" endIcon={<SkipNoSign />} color="error">
                        Skip
                    </Button>
                </Stack>
            </ListItem>
        );
    } else {
        return (
            <ListItem divider={lastStep} sx={{ opacity: ignoreOpacity }} key={dateTimeString(stageStep.step.startTime)}>
                <ListItemText inset primary={stageStep.step.instructions} secondary={getLatestNote(stageStep.step)?.notes} />
            </ListItem>
        );
    }
};

const BrewdayStepAction = ({ brewday }: Props) => {
    const db = useMemo(() => forBrew(brewday.brewLink.id), [brewday.brewLink.id]);
    const { defaultStepIdentifier, defaultAction } = useParams<RouteParams>();
    const [action, setAction] = useState(defaultAction);
    const [modalOpen, setModalOpen] = useState(defaultAction === 'complete');
    const [stepIdentifier, _setStepIdentifier] = useState(defaultStepIdentifier);
    const { stageIndex, stepIndex } = useMemo(() => {
        const index = (stepIdentifier && fromIdentifierToIndex(stepIdentifier)) || null;
        return {
            stageIndex: index?.stageIdentifier,
            stepIndex: index?.stepIdentifier
        };
    }, [stepIdentifier]);
    const isValid = useMemo(() => {
        return stageIndex != null && stepIndex != null && stageIndex >= 0 && stepIndex >= 0 && stageIndex < brewday.stages.length && stepIndex < brewday.stages[stageIndex].steps.length;
    }, [stageIndex, stepIndex, brewday.stages]);

    const currentStepAndStage: StepAndStage | null = useMemo(() => {
        if (isValid) {
            const { stages } = brewday;
            const thisStage = stages[stageIndex!];
            const thisStepIndex = stepIndex!;
            return {
                step: thisStage.steps[thisStepIndex],
                stageName: thisStage.name,
                isPrimary: true
            };
            
        } else {
            return null;
        }
    }, [isValid, stageIndex, stepIndex, brewday]);

    const previousStepAndStage: StepAndStage | null = useMemo(() => {
        if (currentStepAndStage) {
            const { stages } = brewday;
            let previousStepIndex = stepIndex! - 1;
            let previousStageIndex = stageIndex!;
            if (previousStepIndex < 0) {
                previousStageIndex--;
                if (previousStageIndex < 0) {
                    return null;
                }

                previousStepIndex = stages[previousStageIndex].steps.length - 1;
            }

            return {
                step: stages[previousStageIndex].steps[previousStepIndex],
                stageName: stages[previousStageIndex].name,
                isPrimary: false
            };
        } else {
            return null;
        }
    }, [currentStepAndStage, stageIndex, stepIndex, brewday]);

    const nextStepAndStage: StepAndStage | null = useMemo(() => {
        if (currentStepAndStage) {
            const { stages } = brewday;
            let nextStepIndex = stepIndex! + 1;
            let nextStageIndex = stageIndex!;
            if (nextStepIndex >= stages[nextStageIndex].steps.length) {
                nextStageIndex++;
                if (nextStageIndex >= stages.length) {
                    return null;
                }

                nextStepIndex = 0;
            }

            return {
                step: stages[nextStageIndex].steps[nextStepIndex],
                stageName: stages[nextStageIndex].name,
                isPrimary: false
            };
        } else {
            return null;
        }
    }, [currentStepAndStage, stageIndex, stepIndex, brewday]);

    const stepsGroupedByStage = useMemo(() => {
        const group = new Map<string, StepAndStage[]>();
        const add = addToMap(group);
        add(previousStepAndStage);
        add(currentStepAndStage);
        add(nextStepAndStage);

        return group;
    }, [currentStepAndStage, previousStepAndStage, nextStepAndStage]);

    const openModal = useCallback((newAction: string) => () => {
        setModalOpen(true);
        setAction(newAction);
    }, [setModalOpen, setAction]);

    const closeModal = useCallback(() => setModalOpen(false), [setModalOpen]);

    const finishStep = useCallback((notes: string) => {
        db.completeStep(brewday.id, stageIndex!, stepIndex!, notes).then(closeModal);
    }, [brewday.id, stageIndex, stepIndex, closeModal, db]);

    logger.info('Brewday step action data:', currentStepAndStage, previousStepAndStage, nextStepAndStage);

    if (!isValid) {
        return (
            <>
            <Typography variant="h3">Invalid information received.</Typography>
            <Typography variant="subtitle1">Please verify your URL or navigate to stages to continue.</Typography>
            </>
        );
    }

    return (
        <>
        <List>
            {
                [...stepsGroupedByStage.entries()].map(([stageName, stageSteps]) => {
                    const opacity = stageSteps.some(s => s.isPrimary) ? 1 : ignoreOpacity;
                    return (
                        <Fragment key={stageName}>
                        <ListSubheader sx={{ opacity }}>
                            <Typography variant="h5">{stageSteps[0].stageName}</Typography>
                        </ListSubheader>
                        {
                            stageSteps.map((stageStep, index) => getListItem(stageStep, index === stageSteps.length - 1, openModal('complete'), openModal('skip')))
                        }
                        </Fragment>
                    );
                })
            }
        </List>
        <Dialog open={modalOpen} onClose={(_, reason) => reason === 'escapeKeyDown' && closeModal()}>
            <CompleteStepForm defaultNotes={getDefaultNotes(action)} onSave={finishStep} onCancel={closeModal}/>
        </Dialog>
        </>
    );
};

export default BrewdayStepAction;