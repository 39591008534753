import { db } from '../firebase/firestore';
import { collection, collectionGroup, getDocs, query, where, QueryConstraint, Query, doc, getDoc, setDoc, CollectionReference, documentId, onSnapshot } from 'firebase/firestore';
import { Entity } from '../models/entity';

interface GetData { data(): any };

export const baseConverter = <T extends Entity>() => ({
    toFirestore(data: T) {
        return data;
    },
    fromFirestore(snap: GetData) {
        return snap.data() as T;
    }
});

export type Identified<T> = T & { id: string };

export const identify = <T extends { id?: string }>(data: T, id: string): Identified<T> => {
    return {
        ...data,
        id
    };
};

export type RepositoryOptions = {
    collectionName: string,
    isCollectionGroup?: boolean
};

export type Sort<T> = {
    column: keyof T,
    descending: boolean
};

export const repoFactory = <T extends Entity>(opts: RepositoryOptions) => {
    return {
        converter: baseConverter<T>(),
        col() { 
            return (opts.isCollectionGroup ? collectionGroup(db, opts.collectionName) : collection(db, opts.collectionName))
                .withConverter(this.converter);
        },
        getQuery(...constraints: QueryConstraint[]) {
            return query(this.col(), ...constraints);
        },
        isCollection(possibleCollection: Query<T>): possibleCollection is CollectionReference<T> {
            return !opts.isCollectionGroup;
        },
        async getAll(q?: Query<T>) {
            const queryResult = await getDocs(q || this.col());
            return queryResult.docs.map(x => identify(x.data(), x.id));
        },
        async filter(...constraints: QueryConstraint[]) {
            return await this.getAll(this.getQuery(...constraints));
        },
        async getAllActive() {
            return await this.getAll(this.getQuery(where('active', '==', true)));
        },
        getDoc(id?: string) {
            const col = this.col();
            if (this.isCollection(col)) {
                return id ? doc(col, id) : doc(col);
            }

            return null;
        },
        getRequiredDoc(id?: string) {
            const thisDoc = this.getDoc(id);
            if (thisDoc === null) {
                throw Error('Repo tried retrieving a single record for a collection group, which is not allowed.');
            }

            return thisDoc;
        },
        async getOne(id: string): Promise<T | null> {
            const thisDoc = this.getDoc(id);

            if (thisDoc) {
                const snap = await getDoc(thisDoc);
                if (snap.exists()) {
                    const data = snap.data();
                    data.id = snap.id;

                    return data;
                }
            }

            return null;
        },
        async getManyByIds(ids: string[]): Promise<T[]> {
            const groupedIds = [];
            let start = 0;
            const length = ids.length;
            while (start < length) {
              groupedIds.push(ids.slice(start, Math.min(start + 10, length)));
              start += 10;
            }
      
            const results = await Promise.all(groupedIds.map(group => this.getAll(this.getQuery(where(documentId(), 'in', group)))));
            return results.reduce((acc, cur) => [...acc, ...cur], []);
        },
        listen(callback: (data: T[]) => void, ...predicates: QueryConstraint[]) {
            return onSnapshot(this.getQuery(...predicates), snapshot => callback(snapshot.docs.map(doc => identify(doc.data(), doc.id))));
        },
        listenActive(callback: (data: T[]) => void, ...predicates: QueryConstraint[]) {
            return this.listen(callback, where('active', '==', true), ...predicates);
        },
        listenToDocument(callback: (data: T | null) => void, id: string) {
            return onSnapshot(this.getRequiredDoc(id), snapshot => callback(snapshot.exists() ? identify(snapshot.data(), snapshot.id) : null));
        },
        async save(data: T) {
            const thisDoc = this.getRequiredDoc(data.id);
        
            await setDoc(thisDoc, data);
            data.id = thisDoc.id;
            return data;
        }
    };
};