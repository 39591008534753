import { ReactNode, useCallback, useRef } from 'react';
import { DragSourceMonitor, DropTargetMonitor, useDrag, useDrop } from 'react-dnd';

type Props<T, D> = {
    itemType: string,
    item: T,
    renderRow: (item: T, dragProps: D) => ReactNode,
    onHover: (draggedItem: T, hoveredItem: T, monitor: DropTargetMonitor<T, void>) => void,
    collect: (monitor: DragSourceMonitor<T, void>) => D,
    onDrop: (item: T, monitor: DropTargetMonitor<T, void>) => void
};

const ResortableTableRow = <T, D>({ item, itemType, renderRow, onHover, collect, onDrop }: Props<T, D>) => {
    const ref = useRef<HTMLDivElement>(null);
    const [dragProps, drag] = useDrag(() => ({
        type: itemType,
        item: () => item,
        collect
    }));

    const hover = useCallback((dragItem: T, monitor: DropTargetMonitor<T, void>) => onHover(dragItem, item, monitor), [onHover, item]);

    const [{ handlerId }, drop] = useDrop({
        accept: itemType,
        collect: monitor => ({
            handlerId: monitor.getHandlerId()
        }),
        hover,
        drop: onDrop
    });

    drag(drop(ref));

    return (
        <div ref={ref} data-handler-id={handlerId}>
            {renderRow(item, dragProps)}
        </div>
    );
};

export default ResortableTableRow;
