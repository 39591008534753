import { Button, Card, CardActions, CardContent, CardHeader, Stack, TextField, Typography } from '@mui/material';
import { useCallback, useState } from 'react';

type Props = {
    onSave: (notes: string) => void
    onCancel: () => void,
    defaultNotes?: string
};

const CompleteStepForm = ({
    onSave,
    onCancel,
    defaultNotes
}: Props) => {
    const [notes, setNotes] = useState(defaultNotes || '');
    const save = useCallback(() => onSave(notes), [onSave, notes]);

    return (
        <Card sx={{ minWidth: 350, maxWidth: 500 }}>
            <CardHeader title="Mark step as completed" />
            <CardContent>
                <Stack>
                    <Typography variant="h6">Would you like to add a note?</Typography>
                    <TextField
                    value={notes}
                    onChange={e => setNotes(e.target.value)}
                    multiline
                    rows={3}
                    />
                </Stack>
            </CardContent>
            <CardActions>
                <Button onClick={onCancel}>Cancel</Button>
                <Button color="primary" onClick={save}>Complete</Button>
            </CardActions>
        </Card>
    );
};

export default CompleteStepForm;