import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import BottleTable, { SortableProps } from '../../components/bottles/BottleTable';
import { AddIcon } from '../../components/icons';
import useActions from '../../hooks/useActions';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import useSort from '../../hooks/useSort';
import { Bottle } from '../../models/bottle';
import { PopulatedBrewday } from '../../models/brewday';
import bottleRepoFactory from '../../repositories/bottles';

type Props = {
    brewday: PopulatedBrewday
};

const BrewdayBottles = ({ brewday }: Props) => {
    const navigate = useNavigate();
    const currentUser = useCurrentUser();
    const [bottles, setBottles] = useState([] as Bottle[]);
    const [loading, setLoading] = useState(false);
    const [sort, onSort] = useSort<SortableProps>();

    const brewId = brewday.brew.id;
    const brewdayId = brewday.id;
    const repo = useMemo(() => bottleRepoFactory(brewId, brewdayId), [brewId, brewdayId]);

    useEffect(() => {
        if (!currentUser) {
            return;
        }
        setLoading(true);
        repo.getAll(currentUser.userId, sort).then(brewBottles => {
            setBottles(brewBottles);
            setLoading(false);
        });
    }, [currentUser, repo, sort]);

    useActions([
        {
            icon: AddIcon,
            label: 'Create',
            onSelected: () => navigate('create')
        }
    ], [navigate]);

    return loading ? <LinearProgress /> : (
        <Box sx={{ minWidth: 650, width: '50%' }}>
            <BottleTable bottles={bottles} sort={sort} onSort={onSort} onBottleSelected={b => navigate(b.id!)} />
        </Box>
    );
};

export default BrewdayBottles;
