import LinearProgress from '@mui/material/LinearProgress';
import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import BottleDetails from '../../components/bottles/BottleDetails';
import usePopulatedBottle from '../../hooks/usePopulatedBottle';
import { Bottle } from '../../models/bottle';
import bottleRepoFactory from '../../repositories/bottles';

type Props = {
    brewId: string,
    brewdayId: string,
    bottleId: string
};

const BrewBottleDetails = ({ brewId, brewdayId, bottleId }: Props) => {
    const [bottle, setBottle] = useState<Bottle | null>(null);
    const repo = useMemo(() => bottleRepoFactory(brewId, brewdayId), [brewId, brewdayId]);
    useEffect(() => {
        repo.getOne(bottleId).then(b => setBottle(b));
    }, [repo, bottleId]);

    const populatedBottle = usePopulatedBottle(bottle);

    return !populatedBottle ? <LinearProgress /> : (
        <BottleDetails bottle={populatedBottle} />
    );
};

export const RoutedBrewBottleDetails = () => {
    const { brewId, bottleId, brewdayId } = useParams<Props>();
    if (!brewId || !brewdayId || !bottleId) {
        return null;
    }

    return <BrewBottleDetails brewId={brewId} bottleId={bottleId} brewdayId={brewdayId} />;
};

export default BrewBottleDetails;
