import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom';

import Navigation from './Navigation';
import Brews from '../../pages/brews/Brews';
import Brewdays from '../../pages/brewdays/Brewdays';
import BrewEdit from '../../pages/brews/BrewDetailsEdit';
import BrewRoutes from '../../pages/brews/BrewRoutes';
import BrewdayRoutes from '../../pages/brewdays/BrewdayRoutes';
import { redirectRoutePrefix } from '../../firebase/auth';
import AuthRedirectRoutes from '../../pages/auth/AuthRedirectRoutes';
const Routes = () => {
    return (
        <ReactRoutes>
            <Route element={<Navigation />}>
                <Route index element={<Navigate to="/brews" replace />} />
                <Route path="/brews" element={<Brews />} />
                <Route path="/brews/create" element={<BrewEdit />}/>
                <Route path="/brewdays" element={<Brewdays />} />
                <Route path="/brewdays/:brewId/:brewdayId/*" element={<BrewdayRoutes />} />
                <Route path="/brews/:brewId/*" element={<BrewRoutes />}/>
                <Route path={`${redirectRoutePrefix}/*`} element={<AuthRedirectRoutes />} />
            </Route>
        </ReactRoutes>
    );
};

export default Routes;
