import Add from '@mui/icons-material/AddCircleOutline';
import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import Save from '@mui/icons-material/Save';
import Cancel from '@mui/icons-material/Cancel';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import Draggable from '@mui/icons-material/DragHandle';
import ListItemMenu from '@mui/icons-material/MoreVert';
import DoNotDisturb from '@mui/icons-material/DoNotDisturb';
import Done from '@mui/icons-material/Done';
import SportsBarRounded from '@mui/icons-material/SportsBarRounded';
import Close from '@mui/icons-material/Close';
import FilterList from '@mui/icons-material/FilterList';
import Publish from '@mui/icons-material/Publish';
import LockClosed from '@mui/icons-material/LockOutlined';
import LockOpen from '@mui/icons-material/LockOpen';

export const AddIcon = Add;
export const EditIcon = Edit;
export const DeleteIcon = Delete;
export const SaveIcon = Save;
export const CancelIcon = Cancel;
export const ExpandMoreIcon = ExpandMore;
export const ExpandLessIcon = ExpandLess;
export const DraggableIcon = Draggable;
export const ListItemMenuIcon = ListItemMenu;
export const CompleteCheckmark = Done;
export const SkipNoSign = DoNotDisturb;
export const BeerIcon = SportsBarRounded;
export const CloseIcon = Close;
export const filterIcon = FilterList;
export const PublishIcon = Publish;
export const FinalizeIcon = LockClosed;
export const UnfinalizeIcon = LockOpen;

type SelectedProps = { selected?: boolean };
export const SelectedListItemIcon = ({ selected }: SelectedProps) => selected ? <Done /> : null;
