import { darken, styled } from '@mui/material';
import TableRow from '@mui/material/TableRow';

const SelectableTableRow = styled(TableRow)(({ theme }) => ({
    ':hover': {
        cursor: 'pointer',
        backgroundColor: darken(theme.palette.background.paper, 0.1)
    }
}));

export default SelectableTableRow;