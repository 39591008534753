import { app } from './app';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import config from '../config';

export const db = getFirestore(app);

if (config.emulate.firestore) {
    connectFirestoreEmulator(db, 'localhost', 8080);
}

