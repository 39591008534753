import { Stack, Divider, Typography, IconButton } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import { DragSourceMonitor } from 'react-dnd';
import dragTypes from '../../drag-types';
import { StageTemplate } from '../../models/template';
import ResortableTableBody, { Index } from '../table/ResortableTableBody';
import StageTemplateRow from './StageTemplateRow';
import { AddIcon } from '../icons';
import StageTemplateDialog from './StageTemplateDialog';
import { max } from '../../helpers/functions';

type Props = {
    initialStages?: StageTemplate[],
    brewName: string,
    onSave: (templates: StageTemplate[]) => void,
    onCancel: () => void
};

type DragProps = { isDragging: boolean };

const StageTemplates = ({ initialStages, brewName }: Props) => {
    const identifiedStages = useMemo(() => (initialStages || []), [initialStages]);

    const [indexes, setIndexes] = useState(identifiedStages.map((_, idx) => idx));
    const [stages, setStages] = useState<(StageTemplate | null)[]>(identifiedStages);
    const deleteStage = useCallback((index: number) => () => {
        setStages(s => [...s.slice(0, index), null, ...s.slice(index + 1)]);
    }, [setStages]);
    const updateStage = useCallback((index: number) => (template: StageTemplate) => {
        setStages(s => [...s.slice(0, index), template, ...s.slice(index + 1)]);
    }, [setStages]);

    const renderRow = useCallback(({ index }: Index, drag: DragProps) => {
        const stage = stages[index];
        return stage && <StageTemplateRow stage={stage} isDragging={drag.isDragging} onDelete={deleteStage(index)} onUpdate={updateStage(index)} id={index + ''}/>;
    }, [deleteStage, updateStage, stages]);

    const [newStageOpen, setNewStageOpen] = useState(false);
    const startNewStage = useCallback(() => setNewStageOpen(true), [setNewStageOpen]);
    const closeNewStage = useCallback(() => setNewStageOpen(false), [setNewStageOpen]);
    const saveNewStage = useCallback((template: StageTemplate) => {
        setStages(s => [...s, template]);
        setIndexes(idx => [...idx, max(idx) + 1]);
        closeNewStage();
    }, [setStages, setIndexes, closeNewStage]);

    const collect = useCallback((monitor: DragSourceMonitor<number, void>) => ({ isDragging: !!monitor.isDragging() }), []);
    return (
        <>
            <Stack direction="row" justifyContent="space-between">
                <Typography component="h1" variant="h4">Steps to brew a batch of {brewName}:</Typography>
                <Stack direction="row">
                <IconButton size="large" title="Add new stage" onClick={startNewStage}>
                    <AddIcon fontSize="inherit" />
                </IconButton>
                </Stack>
            </Stack>
            <Divider sx={{ marginTop: theme => theme.spacing(2), marginBottom: theme => theme.spacing(2) }} />
            <ResortableTableBody itemType={dragTypes.STAGE} indexes={indexes} onRenderRow={renderRow} onCollect={collect} onReorder={setIndexes}/>
            <StageTemplateDialog fullWidth maxWidth="sm" open={newStageOpen} onCancel={closeNewStage} onSave={saveNewStage} />
        </>
    );
};

export default StageTemplates;