import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';

export type Props<T> = {
    label: string,
    value: T | null,
    required?: boolean,
    onChange: (newValue: T | null) => void,
    getOptions: () => Promise<T[]>,
    getLabelForOption: (opt: T) => string,
    areOptionsEqual: (opt1: T, opt2: T) => boolean,
    groupBy?: (opt: T) => string
};

const LazyPicker = <T, >({
    label,
    value,
    onChange,
    getOptions,
    getLabelForOption,
    areOptionsEqual,
    groupBy,
    required
}: Props<T>) => {
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([] as T[]);

    useEffect(() => {
        let current = true;
        setLoading(true);
        getOptions().then(opts => {
            if (current) {
                setOptions(opts);
                setLoading(false);
            }
        });

        return () => { current = false; };
    }, [getOptions, setOptions, setLoading]);

    return (
        <Autocomplete
        fullWidth
        value={value}
        options={options}
        loading={loading}
        disableClearable={required}
        getOptionLabel={getLabelForOption}
        onChange={(_, newValue) => onChange(newValue)}
        isOptionEqualToValue={areOptionsEqual}
        noOptionsText="No styles match the given text"
        groupBy={groupBy}
        renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label={label}
              required={required}
            />
        )}
        />
    );
};

export default LazyPicker;