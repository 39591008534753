import { createTheme } from '@mui/material/styles';
import green from '@mui/material/colors/green';
import yellow from '@mui/material/colors/yellow';
import { LinkProps } from '@mui/material/Link';

import LinkBehavior from './LinkBehavior';

declare module '@mui/material/styles' {
  interface Palette {
    steps: {
      complete: Palette['primary'],
      inProgress: Palette['primary']
    }
  }

  interface PaletteOptions {
    steps: {
      complete: PaletteOptions['primary'],
      inProgress: PaletteOptions['primary']
    }
  }
}

const themeOptions = {
  palette: {
    primary: green,
    secondary: yellow,
    steps: {
      complete: { main: green[100] },
      inProgress: { main: yellow[100] }
    }
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior
      } as LinkProps
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior
      }
    }
  },
  overrides: {
    MuiTableRow: {
      root: {
        height: '100%'
      }
    }
  }
};

const theme = createTheme(themeOptions);

export default theme;
