import { baseConverter, identify, repoFactory, Sort } from './base';
import { Bottle } from '../models/bottle';
import { collectionGroup, getDocs, orderBy, query, QueryConstraint, where } from 'firebase/firestore';
import { db } from '../firebase/firestore';

const converter = baseConverter<Bottle>();
const columnMap: Record<string, string> = {
  brewdayLink: 'brewdayLink.brewDate'
};

const getSortColumn = (s: keyof Bottle): string => columnMap[s] ?? s;

export const bottlesRepoForBrewFactory = (brewId: string) => ({
  async getAllPublishedForBrew(sort?: Sort<Bottle> | null) {
      const col = collectionGroup(db, 'beers')
        .withConverter(converter);

      const queryParts: QueryConstraint[] = [where('brewdayLink.brewId', '==', brewId), where('published', '==', true)];
      if (sort) {
        queryParts.push(orderBy(getSortColumn(sort.column), sort.descending ? 'desc' : 'asc'));
      }
      
      const queryResults = await getDocs(query(col, ...queryParts));
      return queryResults.docs.map(doc => identify(doc.data(), doc.id));
  }
});

const bottlesRepoFactory = (brewId: string, brewdayId: string) => {
  const path = `brews/${brewId}/brewdays/${brewdayId}/beers`;
  const base = repoFactory<Bottle>({ collectionName: path });

  const repo = {
    async getAll(ownerId: string, sort?: Sort<Bottle> | null) {
      const queryParts: QueryConstraint[] = [where('brewdayLink.ownerId', '==', ownerId)];
      if (sort) {
        queryParts.push(orderBy(getSortColumn(sort.column), sort.descending ? 'desc' : 'asc'));
      }
      
      return await base.filter(...queryParts);
    }
  };

  return {
    ...base,
    ...repo
  };
};

export default bottlesRepoFactory;