import { ReactNode, useCallback, useEffect, useState } from 'react';
import { DragSourceMonitor } from 'react-dnd';
import ResortableTableRow from './ResortableTableRow';

type Props<D> = {
    indexes: number[],
    itemType: string,
    onRenderRow: (item: Index, dragProps: D) => ReactNode,
    onCollect: (monitor: DragSourceMonitor<Index, void>) => D,
    onReorder: (items: number[]) => void
};

export type Index = {
    index: number
};

const ResortableTableBody = <D extends unknown>({ indexes, itemType, onReorder, onCollect, onRenderRow }: Props<D>) => {
    const [internalIndexes, setInternalIndexes] = useState(indexes);
    useEffect(() => {
        setInternalIndexes(indexes);
    }, [setInternalIndexes, indexes]);

    const onHover = useCallback(({ index: draggedIndex }: Index, { index: hoveredIndex }: Index) => {
        if (draggedIndex === hoveredIndex) {
            return;
        }

        setInternalIndexes(prev => {
            const copy = [...prev];
            const currentIndex = copy.findIndex(item => item === draggedIndex);
            const hoverIndex = copy.findIndex(item => item === hoveredIndex);
            copy.splice(currentIndex, 1);
            // const offset = currentIndex > hoverIndex ? 1 : 0;

            copy.splice(hoverIndex, 0, draggedIndex);

            return copy;
        });
    }, [setInternalIndexes]);

    const onDrop = useCallback(() => {
        onReorder(internalIndexes);
    }, [onReorder, internalIndexes]);

    return (
        <>
        {internalIndexes.map(index => <ResortableTableRow key={index} item={{ index }} itemType={itemType} collect={onCollect} renderRow={onRenderRow} onHover={onHover} onDrop={onDrop}/>)}
        </>
    );
};

export default ResortableTableBody;
