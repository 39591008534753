import { useState } from 'react';
import { Builder, ConverterMap, FilterType } from '../repositories/filter';

const useRepoFilter = <E, T extends FilterType>(defaultFilter: T, converters?: ConverterMap<E, T>) => {
    const [builder, setBuilder] = useState<Builder<E, T>>(new Builder(defaultFilter, converters));

    return {
        builder,
        setBuilder,
        handleToggle: (key: keyof T) => () => setBuilder(b => b.toggle(key))
    };
};

export default useRepoFilter;