import { useCallback } from 'react';

import TableCell from '@mui/material/TableCell';

import { Bottle } from '../../models/bottle';
import SelectableTableRow from '../table/SelectableTableRow';
import StarRating from '../StarRating';
import { dateTimeString } from '../../helpers/format';

type Props = {
    bottle: Bottle,
    includeBrewdate?: boolean,
    onBottleSelected: (bottle: Bottle) => void
};

const BottleRow = ({ bottle, onBottleSelected, includeBrewdate }: Props) => {
    const onClick = useCallback(() => onBottleSelected(bottle), [onBottleSelected, bottle]);

    return (
        <SelectableTableRow onClick={onClick}>
            <TableCell>{dateTimeString(bottle.servingTime)}</TableCell>
            { includeBrewdate && <TableCell>{dateTimeString(bottle.brewdayLink.brewDate)}</TableCell> }
            <TableCell align="right">{bottle.servingNumber}</TableCell>
            <TableCell><StarRating rating={bottle.rating} /></TableCell>
        </SelectableTableRow>
    );
};

export default BottleRow;