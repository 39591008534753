import { withContext } from './withContext';
import config from '../config';

export type WindowOpener = (path: string) => Promise<WindowClient | null>;

export const defaultWindowOpener: WindowOpener = withContext({
    application: (context) => (path: string) => {
        context.location = path;
        return Promise.resolve(null);
    },
    serviceWorker: (global) => (path: string) => global.clients.openWindow(`${config.app.baseUrl}/${path}`)
});
