import { useCallback, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteForever';
import { useNavigate } from 'react-router-dom';
import Field from '../../components/Field';
import StarRating from '../../components/StarRating';
import { percentFormat } from '../../helpers/format';
import { PopulatedBrew } from '../../models/brew';
import { Tag } from '../../models/tag';
import useActions from '../../hooks/useActions';
import { BeerIcon, FinalizeIcon, PublishIcon, UnfinalizeIcon } from '../../components/icons';
import NewBrewdayModal from '../../components/brewdays/NewBrewdayModal';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import ConfirmationDialog from '../../components/confirm/ConfirmationDialog';
import brewRepo from '../../repositories/brews';
import logger from '../../helpers/logger';
import { DateTime } from 'luxon';
import brewdayService from '../../services/brewday-starter';

type Props = {
    brew: PopulatedBrew
};

const join = (array: string[] = []) => array.join(', ') || 'None (yet)';
const joinTags = (tags: Tag[]) => join(tags.map(tag => tag.value));

const cannotEditFinalizedBrewsMessage = 'Cannot edit locked or published brews';

const BrewDetails = ({ brew }: Props) => {
    const navigate = useNavigate();
    const currentUser = useCurrentUser();
    const [newBrewdayOpen, setNewBrewdayOpen] = useState(false);

    const finalizeBrew = useCallback(() => brewRepo.finalize(brew.id), [brew.id]);
    const unfinalizeBrew = useCallback(() => brewRepo.unfinalize(brew.id), [brew.id]);

    const [publishOpen, setPublishOpen] = useState(false);
    const openPublish = useCallback(() => setPublishOpen(true), [setPublishOpen]);
    const closePublish = useCallback(() => setPublishOpen(false), [setPublishOpen]);
    const publishBrew = useCallback(() => {
        brewRepo.publish(brew.id).then(closePublish);
    }, [brew.id, closePublish]);

    const publishAction = useMemo(() => {
        if (!brew.published && brew.finalized) {
            return {
                label: 'Publish',
                onSelected: openPublish,
                icon: PublishIcon
            };
        }

        return null;
    }, [brew.published, brew.finalized, openPublish]);

    const finalizeAction = useMemo(() => {
        if (brew.published) {
            return null;
        }

        if (brew.finalized) {
            return {
                label: 'Unlock',
                icon: UnfinalizeIcon,
                onSelected: unfinalizeBrew
            };
        }

        return {
            label: 'Lock',
            icon: FinalizeIcon,
            onSelected: finalizeBrew
        };
    }, [brew.published, brew.finalized, finalizeBrew, unfinalizeBrew]);

    const actionList = useMemo(() => {
        if (currentUser?.userId !== brew.ownerId) {
            return [];
        }

        return [
        ...(publishAction ? [publishAction] : []),
        {
            label: 'Delete',
            onSelected: () => console.log('Deleting this brew'),
            icon: DeleteIcon
        }, {
            label: brew.finalized ? cannotEditFinalizedBrewsMessage : 'Edit',
            onSelected: () => navigate('edit'),
            disabled: brew.finalized,
            icon: EditIcon
        },
        ...(finalizeAction ? [finalizeAction] : []),
        {
            icon: BeerIcon,
            label: 'Create',
            onSelected: () => setNewBrewdayOpen(true)
        }];
    }, [navigate, brew.finalized, brew.ownerId, currentUser, publishAction, finalizeAction]);

    useActions(actionList, [navigate, brew.published, brew.ownerId, currentUser, publishAction, openPublish]);

    const closeNewBrewday = useCallback(() => setNewBrewdayOpen(false), [setNewBrewdayOpen]);
    const handleNewBrewday = useCallback((brewId: string, expectedStartDate: DateTime) => {
        if (!currentUser) {
            return;
        }
        logger.debug('Starting a new brewday for brew', brewId, 'at', expectedStartDate);
        brewdayService.startBrewday(brewId, currentUser.userId, expectedStartDate).then(brewday => navigate(`${brewday.id}`));
    }, [currentUser, navigate]);

    const styleDescription = useMemo(() => {
        if (typeof brew.style === 'string') {
            return `Unknown style: ${brew.style}`;
        } else {
            return `${brew.style.name} (${brew.style.category})`;
        }
    }, [brew.style]);

    return (
        <>
            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h4" component="h1">{brew.name}</Typography>
                <Typography variant="body1">{brew.description}</Typography>
            </Box>
            <Box sx={{ maxWidth: 250 }}>
                <StarRating rating={brew.averageRating} />
            </Box>
            <Typography variant="h6" component="h2" sx={{ marginBottom: 1 }}>More Details:</Typography>
            <Field label="ABV" value={percentFormat(brew.abv)}/>
            <Field label="Style" value={styleDescription} />
            <Field label="Flavors" value={joinTags(brew.experience.flavors)} />
            <Field label="Appearances" value={joinTags(brew.experience.appearances)} />
            <Field label="Aromas" value={joinTags(brew.experience.aromas)} />
            <Field label="Mouthfeels" value={joinTags(brew.experience.mouthfeels)} />
            <NewBrewdayModal open={newBrewdayOpen} onCancel={closeNewBrewday} onContinue={handleNewBrewday} brewId={brew.id} brewName={brew.name}/>
            <ConfirmationDialog
            open={publishOpen}
            onCancel={closePublish}
            onConfirm={publishBrew}
            text="Publishing a brew makes this brew available to the public and can no longer be changed. Consider this putting your label on your bottles or cans: you can't go back from this!"
            />
        </>
    );
};

export default BrewDetails;