import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import FridgeIcon from '@mui/icons-material/Kitchen';
import KettleIcon from '@mui/icons-material/ScienceSharp';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { Outlet } from 'react-router-dom';
import HeaderActions from './HeaderActions';
import ProfileActionsContainer from '../login/ProfileActionsContainer';
import { ListItemButton } from '@mui/material';

const drawerWidth = 240;

const Navigation = () => {
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Brewday Helper
                    </Typography>
                    <HeaderActions />
                </Toolbar>
            </AppBar>
            <Drawer
            variant="permanent"
            anchor="left"
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' }
              }}
            >
                <Toolbar />
                <List>
                    <ListItem>
                        <ListItemButton component={Link} href="/brews">
                            <ListItemIcon>
                                <FridgeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Brews" />
                        </ListItemButton>
                    </ListItem>
                    <ListItem>
                        <ListItemButton component={Link} href="/brewdays">
                            <ListItemIcon>
                                <KettleIcon />
                            </ListItemIcon>
                            <ListItemText primary="Brewdays" />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Toolbar />
                <Outlet />
            </Box>
            <ProfileActionsContainer />
        </Box>
    );
};

export default Navigation;