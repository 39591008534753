type LocalAuth = {
  email: string,
  password: string
};

export enum LoggingLevel {
  DEBUG = 1,
  INFO = 2,
  WARNING = 3,
  ERROR = 4,
  NONE = 5
};

type Messaging = {
  vapidKey: string
};

type Application = {
  baseUrl: string
};

type Emulate = {
  firestore: boolean
};

type Config = {
  localAuth: LocalAuth | null,
  logging: {
    level: LoggingLevel
  },
  messaging: Messaging,
  app: Application,
  emulate: Emulate
};

const base: Config = {
  localAuth: null,
  logging: {
    level: LoggingLevel.DEBUG
  },
  messaging: {
    vapidKey: process.env.REACT_APP_LOCAL_VAPID_KEY || ''
  },
  app: {
    baseUrl: process.env.REACT_APP_BASE_URL || 'http://localhost:3000'
  },
  emulate: {
    firestore: process.env.REACT_APP_EMULATE_FIRESTORE === 'true'
  }
};

if (process.env.REACT_APP_LOCAL === 'true') {
  base.localAuth = {
    email: process.env.REACT_APP_LOCAL_EMAIL || '',
    password: process.env.REACT_APP_LOCAL_PASS || ''
  };
}

export default base;
