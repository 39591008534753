import { QueryConstraint, Timestamp, where, WhereFilterOp } from 'firebase/firestore';

type StringKeys<T> = keyof T & string;

type ValueType = (string | number | boolean | Timestamp);
type ArrayType = (string[] | number[]);

export type BaseOperation<T> = {
    operand: WhereFilterOp,
    value: any,
    key: StringKeys<T>
};

export type FilterType = Record<string, ValueType | ArrayType>;
export type Converter<T, V> = (value: V) => BaseOperation<T> | null;

export type ConverterMap<E, T extends FilterType> = { [P in keyof T]?: Converter<E, T[P]> };

const needsArrayConverter = (value: ValueType | ArrayType): value is ArrayType => Array.isArray(value);

const missingConverter = <E, T extends FilterType>(key: StringKeys<T>, converters: ConverterMap<E, T>): key is StringKeys<E> => {
    return !(key in converters);
};

const convert = <E, T extends FilterType, K extends StringKeys<T>>(key: K, value: T[K], availableConverters: ConverterMap<E, T>): BaseOperation<E> | null => {
    if (missingConverter(key, availableConverters)) {
        if (needsArrayConverter(value)) {
            if (value.length === 0) {
                return null;
            }
    
            return {
                value: value,
                operand: 'in',
                key
            };
        } else {
            if (value === false) {
                return null;
            }
    
            return {
                value,
                operand: '==',
                key
            };
        }
    }
    
    const converter = availableConverters[key]!;
    return converter(value);
};

const toQueryConstraint = <T>(operation: BaseOperation<T>): QueryConstraint => {
    return where(operation.key, operation.operand, operation.value);
};

type Only<T extends FilterType, S extends ValueType | ArrayType> = keyof {
    [P in keyof T]: T[P] extends (S | undefined) ? T[P] : never
};

export class Builder<E, T extends FilterType>
{
    readonly filter: T;
    constructor(defaults: T, private converters: ConverterMap<E, T> = {}) {
        this.filter = defaults;
    }

    asOperations(): BaseOperation<E>[] {
        const ops: BaseOperation<E>[] = [];
        for (const k in this.filter) {
            const value = this.filter[k];
            if (typeof value === 'undefined') {
                continue;
            }

            const operation = convert(k, value, this.converters);
            if (operation === null) {
                continue;
            }

            ops.push(operation);
        }

        return ops;
    }

    build(): QueryConstraint[] {
        return this.asOperations().map(toQueryConstraint);
    }

    toggle(key: Only<T, boolean>): Builder<E, T> {
        const newFilter = {
            ...this.filter,
            [key]: !this.filter[key]
        };

        return new Builder(newFilter, this.converters);
    }

    with<K extends keyof T>(key: K, value: T[K]): Builder<E, T> {
        const newFilter = {
            ...this.filter,
            [key]: value
        };

        return new Builder(newFilter, this.converters);
    }

    without<K extends keyof T>(key: K): Builder<E, T> {
        const newFilter = {
            ...this.filter
        };

        delete newFilter[key];

        return new Builder(newFilter, this.converters);
    }

    append<A extends string | number>(key: Only<T, A extends string ? string[] : A extends number ? number[] : never>, val: A): Builder<E, T> {
        const existingValues = this.filter[key] as A[];

        const newFilter = {
            ...this.filter,
            [key]: [...existingValues, val]
        };

        return new Builder(newFilter, this.converters);
    }

    remove<A extends string | number>(key: Only<T, A extends string ? string[] : A extends number ? number[] : never>, val: A): Builder<E, T> {
        const existingValues = this.filter[key] as A[];

        const newFilter = {
            ...this.filter,
            [key]: existingValues.filter((v: A) => v !== val)
        };

        return new Builder(newFilter, this.converters);
    }
}

