import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';
import Stages from '../../components/stages/Stages';
import { firstIncompleteStageAndStep, PopulatedBrewday } from '../../models/brewday';

type Props = {
    brewday: PopulatedBrewday
};

const BrewdayStages = ({ brewday }: Props) => {
    const inProgressStageAndStep = useMemo(() => firstIncompleteStageAndStep(brewday), [brewday]);
    return (
        <>
        <Box sx={{ marginBottom: 2 }}>
            <Typography variant="h4" component="h1">{brewday.brew.name}</Typography>
        </Box>
        <Stages stages={brewday.stages} inProgressStageAndStep={inProgressStageAndStep}/>
        </>
    );
};

export default BrewdayStages;