// A specific instance of the creation of a Brew.
// What occured on brew day.

import { Timestamp } from 'firebase/firestore';
import { DateTime } from 'luxon';
import { deepClone, firstSuccessful } from '../helpers/functions';
import logger from '../helpers/logger';
import { Populated } from '../helpers/types';
import { BatchSize } from './batch-size';
import { BrewLink, PopulatedBrew } from './brew';
import { Gravity } from './gravity';
import { Note } from './note';
import { Ownable } from './owned';
import { firstIncompleteStep, Stage } from './stage';
import { Step } from './step';

export type Brewday = Ownable & {
  id?: string,
  startDate: Timestamp,
  batchSize: BatchSize,
  gravities: Gravity[],
  ideas: string,
  notes: Note[],
  readyToServeDate: Timestamp,
  brewLink: BrewLink,
  stages: Stage[]
};

export const getAbv = (brewday: Brewday): number => {
  if (brewday.gravities.length < 2) return 0;

    const initialGravity = brewday.gravities[0].reading;
    const finalGravity = brewday.gravities[brewday.gravities.length - 1].reading;

    return (76.08 * (initialGravity - finalGravity) / (1.775 - initialGravity)) * (finalGravity / 0.794);
};

export const getLink = (brewday: Brewday): BrewdayLink => ({
  id: brewday.id || '',
  brewId: brewday.brewLink.id,
  brewDate: brewday.readyToServeDate,
  ownerId: brewday.ownerId
});

export const isInProgress = (brewday: Brewday): boolean => {
  return DateTime.fromMillis(brewday.readyToServeDate.toMillis()).diffNow().milliseconds >= 0;
};

export const completeStep = (brewday: Brewday, stageIndex: number, stepIndex: number, note?: string, time = Timestamp.now()): Brewday => {
  const copy = deepClone(brewday);
  const step = copy.stages[stageIndex]?.steps[stepIndex];
  if (step) {
    step.endTime = time;
    step.duration = time.toMillis() - step.startTime.toMillis();
    if (note) {
      step.notes.push({ notes: note, timestamp: time });
    }
  } else {
    logger.error('Attempted to complete a step that did not exist:', brewday, 'stage:', stageIndex, 'step:', stepIndex);
  }

  return copy;
};

export type BrewdayLink = Ownable & {
  id: string,
  brewId: string,
  brewDate: Timestamp
};

export const normalize = (populated: PopulatedBrewday): Brewday => ({
  ...populated,
  gravities: [...populated.gravities],
  notes: [...populated.notes],
  stages: [...populated.stages]
});

type PopulatedProperties = {
  id: string,
  brew: PopulatedBrew
};

export type PopulatedBrewday = Populated<Brewday, PopulatedProperties>;

export type StageAndStep = {
  stage: Stage,
  step: Step,
  stageIndex: number,
  stepIndex: number
};

export const getStageAndStepByIndex = (brewday: Brewday | PopulatedBrewday, stageIndex: number, stepIndex: number): StageAndStep | null => {
  if (stageIndex >= 0 && stageIndex < brewday.stages.length) {
    const stage = brewday.stages[stageIndex];
    if (stepIndex >= 0 && stepIndex < stage.steps.length) {
      return {
        stage,
        step: stage.steps[stepIndex],
        stageIndex,
        stepIndex
      };
    }
  }

  return null;
};

export const firstIncompleteStageAndStep = (brewday: Brewday | PopulatedBrewday): StageAndStep | null => {
  const incompleteStage = firstSuccessful(brewday.stages, (stage: Stage) => {
    const step = firstIncompleteStep(stage);
    if (step) {
      return {
        stage,
        step
      };
    }

    return null;
  });

  if (incompleteStage === null) {
    return null;
  }

  return {
    stage: incompleteStage.data.stage,
    step: incompleteStage.data.step.data,
    stageIndex: incompleteStage.index,
    stepIndex: incompleteStage.data.step.index
  };
};
