import { onMessage } from 'firebase/messaging';
import { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { EventData, MessageHandler } from '../messaging/types';
import { messaging } from '../messaging/notifications/service';
import { getHandler, isValidMessage, transform } from '../messaging';
import logger from '../helpers/logger';

export type MessagingContextData = {
    subscribe: <T>(handler: MessageHandler<T>) => string,
    unsubscribe: (subscription: string) => void,
    publishInternal: <T extends EventData<string>>(eventData: T) => void
};

type Props = {
    children?: ReactNode
};

let currentId = 0;
const getId = () => {
    return `${++currentId}`;
};

export const MessagingContext = createContext<MessagingContextData>({
    subscribe: () => '',
    unsubscribe: () => {},
    publishInternal: () => {}
});
  
export const MessagingContextProvider = ({ children }: Props) => {
    const [subscriptions, setSubscriptions] = useState<Map<string, MessageHandler<any>>>(new Map<string, MessageHandler<any>>());

    const subscribe = useCallback(<T extends unknown>(handler: MessageHandler<T>) => {
        const id = getId();
        setSubscriptions(previousSubscriptions => {
            const newMap = new Map<string, MessageHandler<any>>(previousSubscriptions);
            newMap.set(id, handler);
            return newMap;
        });

        return id;
    }, [setSubscriptions]);

    const unsubscribe = useCallback((id: string) => {
        setSubscriptions(previousSubscriptions => {
            const newMap = new Map<string, MessageHandler<any>>(previousSubscriptions);
            newMap.delete(id);
            return newMap;
        });
    }, [setSubscriptions]);

    const subscriptionList = useMemo(() => [...subscriptions.values()], [subscriptions]);
    const publishInternal = useMemo(() => getHandler(subscriptionList), [subscriptionList]);

    useEffect(() => {
        return onMessage(messaging, payload => {
            logger.debug('Received a message from google cloud:', payload);
            if (isValidMessage(payload)) {
                const evt = transform(payload);
                if (evt) {
                    publishInternal(evt);
                } else {
                    logger.debug('Unknown event received:', evt);
                }
            }
        });
    }, [publishInternal]);

    return (
        <MessagingContext.Provider value={{ subscribe, unsubscribe, publishInternal }}>
        { children || null }
        </MessagingContext.Provider>
    );
};