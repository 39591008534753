import { useCallback, useState } from 'react';
import { Sort } from '../repositories/base';

const useSort = <T>(def?: Sort<T>) => {
    const [sort, setSort] = useState(def || null);

    const onSort = useCallback((column: keyof T) => {
        setSort(s => {
            return {
                column,
                descending: s?.column === column ? !s.descending : false
            };
        });
    }, [setSort]);

    return [sort, onSort] as [typeof sort, typeof onSort];
};

export default useSort;
