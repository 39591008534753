import { Appearance, Aroma, Flavor, getLookupForType, Mouthfeel, Tag, Type, normalize as normalizeTag } from './tag';

export type Experience = {
  appearances: string[],
  aromas: string[],
  mouthfeels: string[],
  flavors: string[],
  details: string
};

export type PopulatedExperience = {
  appearances: Appearance[],
  aromas: Aroma[],
  mouthfeels: Mouthfeel[],
  flavors: Flavor[],
  details: string
};

export function getAllTags(exp: Experience): string[]
export function getAllTags(exp: PopulatedExperience): Tag[]
export function getAllTags(exp: Experience | PopulatedExperience) {
  return [...exp.appearances, ...exp.aromas, ...exp.flavors, ...exp.mouthfeels];
}

export const empty: PopulatedExperience = {
    appearances: [],
    aromas: [],
    mouthfeels: [],
    flavors: [],
    details: ''
};

const exists = <T>(x: T | null): x is T => !!x;

export const populateExperience = (exp: Experience, tags: Partial<Record<string, Tag>>): PopulatedExperience => ({
  details: exp.details,
  appearances: exp.appearances.map(getLookupForType(tags, Type.APPEARANCE)).filter(exists),
  aromas: exp.aromas.map(getLookupForType(tags, Type.AROMA)).filter(exists),
  mouthfeels: exp.mouthfeels.map(getLookupForType(tags, Type.MOUTHFEEL)).filter(exists),
  flavors: exp.flavors.map(getLookupForType(tags, Type.FLAVOR)).filter(exists)
});

export const normalize = (exp: PopulatedExperience): Experience => ({
  details: exp.details,
  appearances: exp.appearances.map(normalizeTag),
  aromas: exp.aromas.map(normalizeTag),
  mouthfeels: exp.mouthfeels.map(normalizeTag),
  flavors: exp.flavors.map(normalizeTag)
});

export function copy(exp: Experience): Experience
export function copy(exp: PopulatedExperience): PopulatedExperience
export function copy(exp: Experience | PopulatedExperience){
  return {
    appearances: [...exp.appearances],
    aromas: [...exp.aromas],
    mouthfeels: [...exp.mouthfeels],
    flavors: [...exp.flavors],
    details: exp.details
  };
}

/*

Dark, clear
Pine, earthy
Crisp, bold
Pine, malt, smoky

*/
