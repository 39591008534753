export enum Type {
  APPEARANCE = 'APPEARANCE',
  AROMA = 'AROMA',
  FLAVOR = 'FLAVOR',
  MOUTHFEEL = 'MOUTHFEEL',
  UNKNOWN = 'UNKNOWN',
};

type NewTag = {
  type: Type,
  value: string
};

type InnerTag = NewTag & {
  id: string,
  search: string
};

export const newTag = (tag: NewTag): InnerTag => {
  const search = tag.value.toUpperCase();
  return {
    search: search,
    id: `${tag.type}_${search}`,
    ...tag
  };
};

export type Appearance = InnerTag & { type: Type.APPEARANCE };
export type Aroma = InnerTag & { type: Type.AROMA };
export type Flavor = InnerTag & { type: Type.FLAVOR };
export type Mouthfeel = InnerTag & { type: Type.MOUTHFEEL };
export type Tag = Appearance | Aroma | Flavor | Mouthfeel;

export const getLookupForType = <T extends Type>(map: Partial<Record<string, InnerTag>>, type: T) => (id: string): InnerTag & { type: T } | null => {
  const tag = map[id];
  if (tag && tag.type === type) {
    return {
      ...tag,
      type
    };
  }

  return null;
};

export const normalize = (tag: InnerTag): string => tag.id;
