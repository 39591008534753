import { useFormik } from 'formik';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import tagsRepo from '../../repositories/tags';
import bottleRepoFactory from '../../repositories/bottles';
import { CancelIcon, SaveIcon } from '../../components/icons';
import useActions from '../../hooks/useActions';
import { empty, PopulatedBottle, normalize } from '../../models/bottle';
import { PopulatedBrewday } from '../../models/brewday';
import { getAllTags } from '../../models/experience';
import { Box, Paper, Stack, TextField, Typography } from '@mui/material';
import StarRating from '../../components/StarRating';
import TagList from '../../components/form/TagList';
import { Type } from '../../models/tag';

type Props = {
    initialBottle?: PopulatedBottle,
    brewday: PopulatedBrewday
};

const BrewdayBottleEdit = ({ brewday, initialBottle = empty(brewday) }: Props) => {
    const repo = useMemo(() => bottleRepoFactory(brewday.brew.id, brewday.id), [brewday.brew.id, brewday.id]);
    const navigate = useNavigate();
    const goBack = useCallback(() => navigate('..'), [navigate]);
    const formik = useFormik({
        initialValues: initialBottle,
        onSubmit: async (values) => {
            await tagsRepo.ensureTags(getAllTags(values.experience));
            await repo.save(normalize(values));
            goBack();
        }
    });

    useActions([
        {
            label: 'Discard',
            icon: CancelIcon,
            onSelected: goBack
        },
        {
            label: 'Save',
            icon: SaveIcon,
            onSelected: formik.handleSubmit
        }
    ], [formik.handleSubmit]);

    const handleRatingUpdate = useCallback((rating: number) => {
        formik.setFieldValue('rating', rating);
    }, [formik]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Typography variant="h3" component="h1">{brewday.brew.name}</Typography>
            <Stack spacing={2}>
                <Box sx={{ maxWidth: 450 }}>
                    <StarRating rating={formik.values.rating} editable onChange={handleRatingUpdate} />
                </Box>
                <TextField label="Serving number (if known)" value={formik.values.servingNumber}
                    id="servingNumber"
                    name="servingNumber"
                    onChange={formik.handleChange}
                    error={formik.touched.servingNumber && !!formik.errors.servingNumber}
                    helperText={formik.touched.servingNumber && formik.errors.servingNumber}
                    margin="normal"
                    fullWidth
                    required
                    type="number"
                />
                <Paper sx={{ padding: theme => theme.spacing(2) }}>
                    <Stack spacing={2}>
                        <Typography variant="subtitle1">Describe the experience you had while drinking this beer:</Typography>
                        <TagList label="Flavors" values={formik.values.experience.flavors} type={Type.FLAVOR} onChange={tags => formik.setFieldValue('experience.flavors', tags)}/>
                        <TagList label="Mouthfeels" values={formik.values.experience.mouthfeels} type={Type.MOUTHFEEL} onChange={tags => formik.setFieldValue('experience.mouthfeels', tags)}/>
                        <TagList label="Aromas" values={formik.values.experience.aromas} type={Type.AROMA} onChange={tags => formik.setFieldValue('experience.aromas', tags)}/>
                        <TagList label="Appearances" values={formik.values.experience.appearances} type={Type.APPEARANCE} onChange={tags => formik.setFieldValue('experience.appearances', tags)}/>
                    </Stack>
                </Paper>
                <TextField label="Additional notes" value={formik.values.notes}
                    id="notes"
                    name="notes"
                    onChange={formik.handleChange}
                    error={formik.touched.notes && !!formik.errors.notes}
                    helperText={formik.touched.notes && formik.errors.notes}
                    margin="normal"
                    fullWidth
                    multiline
                />
            </Stack>
        </form>
    );
};

export default BrewdayBottleEdit;