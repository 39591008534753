import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type Props = {
    label: string,
    value: string,
};

const Field = ({ label, value }: Props) => (
    <Box sx={{ marginBottom: 2 }}>
        <Typography variant="subtitle2">{label}:</Typography>
        <Typography variant="body1">{value}</Typography>
    </Box>
);

export default Field;