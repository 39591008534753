import LinearProgress from '@mui/material/LinearProgress';
import { useEffect, useState, useMemo } from 'react';
import { Navigate, Route, Routes, useMatch, useParams } from 'react-router-dom';
import { Brewday, isInProgress } from '../../models/brewday';
import { forBrew } from '../../repositories/brewdays';
import BrewdayDetails from './BrewdayDetails';
import Box from '@mui/material/Box';
import BrewdayStages from './BrewdayStages';
import BrewdayBottles from './BrewdayBottles';
import { RoutedBrewdayBottleDetails } from './BrewdayBottleDetails';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Link from '@mui/material/Link';
import usePopulatedBrewday from '../../hooks/usePopulatedBrewday';
import BrewdayBottleEdit from './BrewdayBottleEdit';
import BrewdayStepAction from './BrewdayStepAction';

type Params = {
    brewId: string
    brewdayId: string
};

const inProgressTabs = [
    <Tab
    key="details"
    component={Link}
    href="details"
    value="details"
    label="Details"
    />,
    <Tab
    key="stages"
    component={Link}
    href="stages"
    value="stages"
    label="Stages"
    />
];

const completedTabs = [
    <Tab
    key="details"
    component={Link}
    href="details"
    value="details"
    label="Details"
    />,
    <Tab
    key="stages"
    component={Link}
    href="stages"
    value="stages"
    label="Stages"
    />,
    <Tab
    key="bottles"
    component={Link}
    href="bottles"
    value="bottles"
    label="Bottles"
    />
];

const BrewdayRoutes = () => {
    const params = useParams<Params>();
    const [brewday, setBrewday] = useState(null as Brewday | null);

    const brewdayRepo = useMemo(() => params.brewId ? forBrew(params.brewId) : null, [params.brewId]);

    useEffect(() => {
        if (params.brewdayId && brewdayRepo) {
            return brewdayRepo.listenToDocument(setBrewday, params.brewdayId);
        }
    }, [params.brewdayId, setBrewday, brewdayRepo]);

    const populatedBrewday = usePopulatedBrewday(brewday);

    const match = useMatch('brewdays/:brewId/:brewdayId/:subroute/*');
    const routeMatch = useMemo(() => match?.params.subroute || false, [match]);

    if (!populatedBrewday) {
        return <LinearProgress />;
    }

    const inProgress = isInProgress(populatedBrewday);

    return (
        <Box sx={{ width: '100%' }}>
            <Tabs value={routeMatch} sx={{ marginBottom: (theme) => theme.spacing(1) }}>
                {inProgress ? inProgressTabs : completedTabs}
            </Tabs>
            <Routes>
                <Route index element={<Navigate to={inProgress ? 'stages' : 'details'} replace />} />
                <Route path="details" element={<BrewdayDetails brewday={populatedBrewday}/>}/>
                <Route path="stages" element={<BrewdayStages brewday={populatedBrewday}/>}/>
                {
                    inProgress && (
                            <Route path="stages/:defaultStepIdentifier/:defaultAction" element={<BrewdayStepAction brewday={populatedBrewday} />} />
                    )
                }
                {
                    !inProgress && (
                        <>
                            <Route path="bottles" element={<BrewdayBottles brewday={populatedBrewday}/>}/>
                            <Route path="bottles/:bottleId" element={<RoutedBrewdayBottleDetails brewday={populatedBrewday}/>}/>
                            <Route path="bottles/create" element={<BrewdayBottleEdit brewday={populatedBrewday}/>}/>
                        </>
                    )
                }
            </Routes>
        </Box>
    );
};

export default BrewdayRoutes;