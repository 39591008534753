import { useCallback } from 'react';

import TableCell from '@mui/material/TableCell';

import { Brewday } from '../../models/brewday';
import SelectableTableRow from '../table/SelectableTableRow';
import { dateString } from '../../helpers/format';

type Props = {
    brewday: Brewday,
    onBrewdaySelected: (brewday: Brewday) => void
};

const BrewdayRow = ({ brewday, onBrewdaySelected }: Props) => {
    const onClick = useCallback(() => onBrewdaySelected(brewday), [onBrewdaySelected, brewday]);

    return (
        <SelectableTableRow onClick={onClick}>
            <TableCell>{brewday.brewLink.name}</TableCell>
            <TableCell>{dateString(brewday.startDate)}</TableCell>
            <TableCell>{dateString(brewday.readyToServeDate)}</TableCell>
        </SelectableTableRow>
    );
};

export default BrewdayRow;