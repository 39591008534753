import config, { LoggingLevel } from '../config';

const loggingLevel = config.logging.level;
const checkLevelAndLog = (level: LoggingLevel, ...args: any[]) => {
  if (level >= loggingLevel) {
    console.log(...args);
  }
};

const logger = {
  debug(...args: any[]) {
    checkLevelAndLog(LoggingLevel.DEBUG, '[DEBUG]: ', ...args);
  },
  info(...args: any[]) {
    checkLevelAndLog(LoggingLevel.INFO, '[INFO]: ', ...args);
  },
  warn(...args: any[]) {
    checkLevelAndLog(LoggingLevel.WARNING, '[WARNING]: ', ...args);
  },
  error(...args: any[]) {
    checkLevelAndLog(LoggingLevel.ERROR, '[ERROR]: ', ...args);
  }
};

export default logger;
