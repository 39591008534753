import { Alert, AlertColor, Button, IconButton, Stack } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { Action } from './types';
import { CloseIcon } from '../icons';

type Props = {
    message: string,
    onClick: (actionId?: string) => void,
    onClose: () => void,
    severity?: AlertColor,
    actions?: Action[]
};

const getActionAttribute = (action: Action, handle: (actionId: string) => void) => <Button key={action.id} color="inherit" onClick={() => handle(action.id)}>{action.text}</Button>;

const Notification = (props: Props) => {
    const { message, onClick, onClose, severity = 'info', actions = [] } = props;
    const actionButtons = useMemo(() => (
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
            { actions.map(a => getActionAttribute(a, onClick)) }
            <IconButton onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </Stack>
    ), [onClick, actions, onClose]);
    const defaultClick = useCallback(() => onClick(), [onClick]);

    return (
        <Alert sx={{ margin: theme => theme.spacing(1), alignItems: 'center' }} severity={severity} action={actionButtons}>
            <Button color="inherit" onClick={defaultClick}>{message}</Button>
        </Alert>
    );
};

export default Notification;