export type Style = {
  name: string,
  category: string,
  id: string
};

export const normalize = (style: Style): string => style.id;

export const getStylePropertyOrId = (styleOrId: Style | string, key: keyof Style) => {
  if (typeof styleOrId === 'string') {
    return styleOrId;
  } else {
    return styleOrId[key];
  }
};
