import { Box, Typography } from '@mui/material';
import Field from '../../components/Field';
import { dateTimeString } from '../../helpers/format';
import { getAbv, PopulatedBrewday } from '../../models/brewday';

type Props = {
    brewday: PopulatedBrewday
};

const BrewdayDetails = ({ brewday }: Props) => {
    return (
        <>
            <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h4" component="h1">{brewday.brew.name}</Typography>
            </Box>
            <Field label="Start Time" value={dateTimeString(brewday.startDate)}/>
            <Field label="Ready to Serve Time" value={dateTimeString(brewday.readyToServeDate)} />
            <Field label="Batch size" value={`${brewday.batchSize} bottles`} />
            <Field label="Measured ABV" value={`${getAbv(brewday).toFixed(2)}%`} />
            <Field label="Ideas/Notes" value={brewday.ideas} />
        </>
    );
};

export default BrewdayDetails;