import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Brewday } from '../../models/brewday';
import BrewdayRow from './BrewdayRow';
import TableHeaderCell from '../table/TableHeaderCell';

type Props = {
    brewdays: Brewday[],
    onBrewdaySelected: (brew: Brewday) => void
};

const BrewdayTable = ({ brewdays, onBrewdaySelected }: Props) => {
    return (
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableHeaderCell>Brew Name</TableHeaderCell>
                        <TableHeaderCell>StartDate</TableHeaderCell>
                        <TableHeaderCell>Ready to Serve Date</TableHeaderCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    { brewdays.map(brewday => <BrewdayRow key={brewday.id} brewday={brewday} onBrewdaySelected={onBrewdaySelected} />) }
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default BrewdayTable;