import { createContext, ReactNode, useEffect, useState } from 'react';
import logger from '../helpers/logger';
import { register } from '../serviceWorkerRegistration';

type Props = {
    children?: ReactNode
};

type Data = {
    swRegistration: ServiceWorkerRegistration | null
};

export const ServiceWorkerContext = createContext<Data>({
    swRegistration: null
});
  
export const ServiceWorkerContextProvider = ({ children }: Props) => {
    const [swRegistration, setSwRegistration] = useState<ServiceWorkerRegistration | null>(null);
    useEffect(() => {
        register({ onSuccess: setSwRegistration, onUpdate: setSwRegistration, onAvailable: setSwRegistration });
    }, [setSwRegistration]);

    const data = {
        swRegistration
    };

    logger.debug('Current service worker registration:', swRegistration);

    return (
        <ServiceWorkerContext.Provider value={data}>
        { children || null }
        </ServiceWorkerContext.Provider>
    );
};