import { AlertColor } from '@mui/material';
import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { Action } from '../components/notifications/types';
import { Nullable, Promiseable } from '../helpers/types';
import useMessage from '../hooks/useMessage';
import { BrewdayStepNotificationData, expectedType } from '../messaging/brewday-step-notification/event';
import { getDisplayText, retrieveRelatedData } from '../messaging/brewday-step-notification/process';
import { getNotificationsService } from '../messaging/notifications/service';
import { EventData } from '../messaging/types';
import { ServiceWorkerContext } from './ServiceWorkerContext';

type BaseMessage = {
    identifier: string,
    duration?: number,
    text: string,
    severity: AlertColor,
    actions: Action[]
};

type EventMessage<T extends string> = BaseMessage & {
    originalEventData: EventData<T>
};

type ActionMessage = BaseMessage & {
    handle: (actionId?: string) => void | boolean
};

export type Message<T extends string = string> = ActionMessage | EventMessage<T>;

type MessageConverter<E extends string, T extends EventData<E>> = (evt: T) => Promiseable<Nullable<EventMessage<E>>>;

const convertStepNotification: MessageConverter<typeof expectedType, BrewdayStepNotificationData> = (evt: BrewdayStepNotificationData) => {
    return retrieveRelatedData(evt).then(({ step }) => ({
        identifier: evt.id,
        severity: 'success',
        text: getDisplayText(evt, step),
        actions: [{
            id: 'complete',
            text: 'Complete'
        }, {
            id: 'skip',
            text: 'Skip'
        }],
        originalEventData: evt
    }));
};

type Data = {
    messages: Message<string>[],
    removeMessage: (messageId: string) => void,
    pushMessage: (message: Message<string>) => void,
    service: ReturnType<typeof getNotificationsService> | null
};

type Props = {
    children?: ReactNode
};

export const NotificationsContext = createContext<Data>({
    messages: [],
    removeMessage: () => {},
    pushMessage: () => {},
    service: null
});
  
export const NotificationsContextProvider = ({ children }: Props) => {
    const { swRegistration } = useContext(ServiceWorkerContext);
    const [messages, setMessages] = useState<Message<string>[]>([]);
    const handleBrewdayStepNotification = useCallback((data: BrewdayStepNotificationData) => {
        Promise.resolve(convertStepNotification(data)).then(msg => msg && setMessages(prevMessages => [...prevMessages, msg]));
    }, []);
    const removeMessage = useCallback((messageId) => setMessages(prevMessages => prevMessages.filter(p => p.identifier !== messageId)), [setMessages]);
    const pushMessage = useCallback((message) => setMessages(prevMessages => [...prevMessages, message]), [setMessages]);
    const service = useMemo(() => swRegistration ? getNotificationsService(swRegistration) : null, [swRegistration]);
    
    useMessage<BrewdayStepNotificationData>(expectedType, handleBrewdayStepNotification);

    const data = {
        messages,
        removeMessage,
        pushMessage,
        service
    };

    return (
        <NotificationsContext.Provider value={data}>
        { children || null }
        </NotificationsContext.Provider>
    );
};