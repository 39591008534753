import { useMemo } from 'react';
import { Brew, PopulatedBrew } from '../models/brew';
import usePopulatedExperience from './usePopulatedExperience';
import usePopulatedStyle from './usePopulatedStyle';

const usePopulatedBrew = (basicBrew?: Brew | null): PopulatedBrew | null => {
    const experience = usePopulatedExperience(basicBrew?.experience);
    const style = usePopulatedStyle(basicBrew?.style);
    
    const populatedBrew = useMemo(() => {
        if (!basicBrew || !style || !experience) {
            return null;
        }

        const pb: PopulatedBrew = {
            ...basicBrew,
            experience,
            style,
            id: basicBrew.id!
        };

        return pb;
    }, [experience, style, basicBrew]);

    return populatedBrew;
};

export default usePopulatedBrew;
