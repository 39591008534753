import { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import BrewdayTable from '../../components/brewdays/BrewdayTable';
import { Brewday } from '../../models/brewday';
import repo, { allConverters, BrewdayFilter } from '../../repositories/brewdays';
import { BeerIcon } from '../../components/icons';
import useActions from '../../hooks/useActions';
import NewBrewdayModal from '../../components/brewdays/NewBrewdayModal';
import useRepoFilter from '../../hooks/useRepoFilter';
import ToggleFilterListItem from '../../components/table/ToggleFilterListItem';
import TableFilterToolbar from '../../components/table/TableFilterToolbar';
import { useCurrentUser } from '../../hooks/useCurrentUser';

const defaultFilter: BrewdayFilter = {
    inProgress: false,
    completed: false
};

const Brewdays = () => {
    const navigate = useNavigate();
    const currentUser = useCurrentUser();
    const [brewdays, setBrewdays] = useState([] as Brewday[]);
    const [newBrewdayOpen, setNewBrewdayOpen] = useState(false);
    const filtersWithUser = useMemo(() => ({ ...defaultFilter, ownerId: currentUser?.userId }), [currentUser]);

    const {
        builder,
        setBuilder
    } = useRepoFilter(filtersWithUser, allConverters);

    const selectCompleted = useCallback(() => setBuilder(b => b.toggle('completed').without('inProgress')), [setBuilder]);
    const selectInProgress = useCallback(() => setBuilder(b => b.toggle('inProgress').without('completed')), [setBuilder]);

    useEffect(() => {
        if (currentUser) {
            repo.filter(...builder.build()).then(brewdayData => {
                setBrewdays(brewdayData);
            });
        }
    }, [builder, currentUser]);

    useActions([
        {
            icon: BeerIcon,
            label: 'Create',
            onSelected: () => setNewBrewdayOpen(true)
        }
    ], [navigate]);
    
    const filterComponents = useMemo(() => [
        <ToggleFilterListItem key="1" onClick={selectCompleted} label="Only completed" selected={!!builder.filter.completed} />,
        <ToggleFilterListItem key="2" onClick={selectInProgress} label="Only in progress" selected={!!builder.filter.inProgress} />
    ], [builder, selectCompleted, selectInProgress]);

    const closeModal = useCallback(() => setNewBrewdayOpen(false), [setNewBrewdayOpen]);
    const handleNewBrewday = useCallback((brewId: string) => {
        console.log('Starting a new brewday for brew', brewId);
    }, []);

    const brewdaySelected = useCallback((brewday: Brewday) => navigate(`/brewdays/${brewday.brewLink.id}/${brewday.id}`), [navigate]);

    return (
        <>
        <TableFilterToolbar tableName="Brewdays" menuItems={filterComponents} />
        <BrewdayTable brewdays={brewdays} onBrewdaySelected={brewdaySelected} />
        <NewBrewdayModal open={newBrewdayOpen} onCancel={closeModal} onContinue={handleNewBrewday} />
        </>
    );
};

export default Brewdays;