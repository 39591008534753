import { IconButton, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { Duration } from 'luxon';
import { useCallback, useMemo, useState } from 'react';
import useMenu, { ActionableMenuItem } from '../../hooks/useMenu';
import { StepTemplate } from '../../models/template';
import { ListItemMenuIcon, DraggableIcon as DragIcon } from '../icons';
import StepTemplateDialog from './StepTemplateDialog';

type Props = {
    step: StepTemplate,
    isDragging?: boolean,
    onDelete: () => void,
    onUpdate: (tmpl: StepTemplate) => void
};

const StepTemplateRow = ({ step, isDragging, onDelete, onUpdate }: Props) => {
    const [isUpdating, setIsUpdating] = useState(false);
    const startUpdating = useCallback(() => setIsUpdating(true), [setIsUpdating]);
    const stopUpdating = useCallback(() => setIsUpdating(false), [setIsUpdating]);
    const finalize = useCallback((tmpl: StepTemplate) => {
        onUpdate(tmpl);
        stopUpdating();
    }, [onUpdate, stopUpdating]);

    const menuItems: ActionableMenuItem[] = useMemo(() => [{ id: 'Delete', onClick: onDelete }, { id: 'Edit', onClick: startUpdating }], [onDelete, startUpdating]);
    const { menu, buttonClick } = useMenu({ icon: ListItemMenuIcon, items: menuItems });
    return (
        <>
        <ListItemButton sx={{ opacity: isDragging ? 0.5 : 1, cursor: 'grab' }}>
            <ListItemIcon>
                <IconButton edge="start" onClick={buttonClick}>
                    <ListItemMenuIcon />
                </IconButton>
            </ListItemIcon>
            <ListItemText primary={`(${Duration.fromMillis(step.duration).shiftTo('minutes').get('minutes')} minutes): ${step.instructions}`} />
            <ListItemIcon>
                <DragIcon />
            </ListItemIcon>
        </ListItemButton>
        <StepTemplateDialog template={step} open={isUpdating} onSave={finalize} onCancel={stopUpdating} />
        {menu}
        </>
    );
};

export default StepTemplateRow;